import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useMediaQuery, Divider } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

//Components
import ImageCard from '../components/ImageCard';
import Header from '../components/Header';
import ListPickerCard from '../components/ListPickerCard';
import TestimonialsCard from '../components/TestimonialsCard';
import ContactCard from '../components/ContactCard';
import Sidebar from './Sidebar';
import IntroCard from './IntroCard';
import Footer from './Footer';
import SectionSeparator from './SectionSeparator';
import IdeologySection from './SectionSeparators/IdeologySection';
import PhotoSectionFetcher from './SectionSeparators/PhotoSectionFetcher';

//Navigation pages
import pages from '../components/navigation/pages';
import Image from './Image';


interface PageProps {
    DarkLogo: string;
    LightLogo: string;
    image: string;
}

const PageDiv = styled.div`
    max-width 100%;
    margin: 0 auto;
    background-color: #fff0;
    overflow-x: hidden;
    // overflow-y: hidden;
`;
const LogoContainer = styled.div`
    width: 250px;
    // width: 110px;
    height: 100%;
    @media(max-width: 600px){
        width: 85px;
        height: 100%;
    }
`;
const ImageCardDiv = styled.div`
    height: 350px;
`;

const ImageCardTypography= styled(Typography)`
    color: white;
`;

const ImageCardGrid = styled(Grid)`
    height: 100%;
`;

const ImageCardTextDiv = styled.div`
    background-color: #00000052;
`;

const CardContainer = styled.div`
    margin-top: 30px;
    margin-bottom: 60px;
`;

const CardsContainer = styled.div`
    margin: 0 auto;
    max-width: 900px;
`;

interface ITest {
    height: number;
    heightMd: number;
}

const StyledImage = styled(Image)`
    width: 100%;
    height: 100%;
`


const Page = ({DarkLogo, LightLogo, image }: PageProps) => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const [openSidebar, setOpenSidebar] = React.useState(false);

    const handleSidebarOpen = () => {
        setOpenSidebar(true);
    };
    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };
    const palette = theme.palette as any;
    const sectionSeparator = palette.sectionSeparator;

    const open = isMd ? false : openSidebar;

    const Logo = (
        <LogoContainer>
            <a href="/" title="FashionForBusiness">
                <StyledImage
                    src={LightLogo}
                    alt="Fashion For Business"
                    lazy={false}
                />
            </a>
        </LogoContainer>
    )

    return (
        <PageDiv className="Page">
            <Header
                LogoIcon={Logo}
                pages={pages}
                onSidebarOpen={handleSidebarOpen}/>
            <Sidebar
                onClose={handleSidebarClose}
                open={open}
                variant="temporary"
                pages={pages}
            />
            <Divider />
            <IntroCard
                image={image}/>
            <Divider />
            <CardContainer>
                <ListPickerCard
                    endpoint={"/categories/list/"}
                />
            </CardContainer>
            <SectionSeparator height={sectionSeparator.heightSm}>
                <IdeologySection />
            </SectionSeparator>
            <CardContainer>
                <TestimonialsCard
                    endpoint={"/customers/testimonials"}
                />
            </CardContainer>
            <SectionSeparator height={sectionSeparator.heightMd}>
                <PhotoSectionFetcher />
            </SectionSeparator>
            <CardContainer>
                <ContactCard
                    endpoint={"/customers/new_prospect"}
                    sent={false} />
            </CardContainer>
            <Footer
                LogoIcon={LightLogo} />
        </PageDiv>
    )
}

export default Page;
