import React from 'react';
import styled from 'styled-components';

interface ISectionSeparator {
    children?: React.ReactNode;
    height: string;
}
interface Tmp {
    height: string;
}

const Wrapper = styled.div<Tmp>`
    background-color: ${props => props.theme.palette.sectionSeparator.color.main};
    color: ${props => props.theme.palette.sectionSeparator.color.contrastText};
    //height: ${props => props.height}px;
    height: 100%;
`;

const SectionSeparator = ({children, height}: ISectionSeparator) => {
    return (
        <Wrapper height={height}>
            {children}
        </Wrapper>
    )
}

export default SectionSeparator;
