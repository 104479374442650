import React, {useState} from 'react';
import {INewImage, ITextImagesArray, ITextImage} from '../../../Interfaces/IImages';
import ImagesSelector from './ImagesSelector';
import styled from 'styled-components';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    useTheme,
    useMediaQuery
} from '@material-ui/core';
import { ImageType } from "react-images-uploading";
import ImageAmountSelection from './ImageAmountSelection';

interface IManagePhotoCollection{
    headline: string;
    description: string;
    isOpen: boolean;
    images: INewImage[];
    handleCancel: () => void;
    handleOkClose: (newImages: ITextImagesArray) => void;
    calledBy?:string;
}

const StyledDialogContent = styled(DialogContent)`
    flex: inherit;
`;

interface IState {
    images: INewImage[]
}

const ManagePhotoCollection = ({headline, description, isOpen, images, handleCancel, handleOkClose, calledBy}: IManagePhotoCollection) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
    const [newImages, setNewImages] = React.useState<ITextImagesArray>({} as ITextImagesArray)
    const [state, setState] = React.useState<IState>({
        images: images
    })


    React.useEffect(() => {
        setState({
            images: images
        });
    },[images])

    const addNewImageCollection = (newImages:Array<Array<INewImage>>) => {
        //var new_images:Array<Array<INewImage>> = newImages as Array<Array<INewImage>>;
        console.log("newImages")
        console.log(newImages)
        var imageList:ITextImage[] = [];
        for (var textImageArr of newImages){
            for (var image of textImageArr){
                console.log(image)
                imageList.push({
                    image: image.image.dataURL,
                    text: image.text? image.text : "",
                    id: image.id
                } as ITextImage);
            }
        }
        var flattenImages:ITextImagesArray = {
            order: 0,
            text_images: imageList
        } as ITextImagesArray;
        setNewImages(flattenImages);
        console.log(flattenImages)
    }

    const GetImages = (imageCount: number) => {
        console.log("Hello")
        var tmpArr:INewImage[] = [];
        for (var i = 0; i < imageCount+1; i++){
            const initialImage:INewImage = {
                image: {
                    dataURL: "",
                    file: {} as File
                } as ImageType,
                text: '',
                index: i,
            }
            tmpArr.push(initialImage);
        }
        setState({
            ...state,
            images: tmpArr,
        })
    }

    const handleClose = () => {
        setState({
            images: []
        })
        handleCancel()
    }

    const handleCloseOnOk = () => {
        setState({
            images: []
        })
        console.log("New images")
        console.log(newImages)
        handleOkClose(newImages)
    } 


    return(
        <Dialog fullScreen={fullScreen} open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{headline}</DialogTitle>
            <StyledDialogContent>
                <DialogContentText>
                    {description}
                </DialogContentText>
            </StyledDialogContent>
            {state.images.length === 0? (
                <ImageAmountSelection  onImageCountSelection={GetImages}/>
            ) :
             (
                 <ImagesSelector
                     images={state.images}
                     onNewImages={addNewImageCollection}
                 />
            )}
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                {
                    state.images.length > 0? (
                        <Button onClick={handleCloseOnOk} color="primary">
                            OK
                        </Button>
                    ) : (<></>)
                }
            </DialogActions>
        </Dialog>
    )
}

export default ManagePhotoCollection;
