import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';

interface IRoundButton{
    action: () => void;
    children?: React.ReactNode;
}

const StyledRoundButton = styled(Button)`
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    padding: 0;
    width: 40px;
    height: 40px;
    //border: 3px solid black;
    background-color: white;
    min-width: 40px;
    &.hover {
        background-color: #c3c3c3;
    }
`;

const RoundButton = ({action, children}: IRoundButton) => {

    return (
        <StyledRoundButton onClick={action}>
            {children}
        </StyledRoundButton>
    )
}

export default RoundButton;
