import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import { ITestId, ITestimonialIdentity } from '../../../Interfaces/ITestimonial';
import {
    Button,
    Grid,
    TextField,
} from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import ImageCrop from '../Utils/ImageCrop';

type TonSelectFile = (evt: React.ChangeEvent<HTMLInputElement>) => void;

const WrapperGrid = styled(Grid)`
    margin-bottom: 30px;
    width: 100%;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
const ImageGridItemContainer = styled(Grid)`
    height: 100%;
`;
const ImagePreview = styled.div`
    border: 1px solid;
    margin: 0 auto;
    margin-bottom: 20px;
`;
const ImageWrapper = styled.div`

`;
const InputWrapper = styled(Grid)`
`;
const InputButton = styled(Button)`

`;

const ReviewIdentityEditor = ({testId, dimensions, title, onComponentUpdated}:ITestimonialIdentity) => {
    const isCircular = dimensions[0]/dimensions[1] === 1? true : false;
    const hiddenFileInput = useRef<HTMLInputElement>(document.createElement("input"));
    const [cropOpen, setCropOpen] = useState(false);
    const [returnValue, setReturnValue] = useState<ITestId>({
        image: testId.image,
        name: testId.name,
    });

    useEffect(() => {
        onComponentUpdated(returnValue);
    }, [returnValue]);

    const onNameChange = (e:any) => {
        const updatedValue:ITestId = {
            ...returnValue,
            name: e.target.value
        };
        setReturnValue(updatedValue);
    }

    const handleClick = () => {
        if(hiddenFileInput !== null){
            hiddenFileInput.current.click();
        }
    }

    const onImageClick : TonSelectFile = (evt) => {
        if (evt.target.files && evt.target.files.length > 0) {
            const reader = new FileReader();
            reader.readAsDataURL(evt.target.files[0]);
            reader.onload = function(evt) {
                setReturnValue({
                    ...returnValue,
                    image: reader.result? reader.result as string : ""
                })
                setCropOpen(true)
            }
        }
    };

    const onCroppedImage = (newImage: string) => {
        setCropOpen(false);
        setReturnValue({
            ...returnValue,
            image: newImage
        });
    }

    const style = {
        width:`${dimensions[0] * 100}px`,
        height:`${dimensions[1] * 100}px`,
        borderRadius: `${dimensions[0]/dimensions[1] === 1? '50%': '0%'}`
    }


    return (
        <>
            <WrapperGrid
                container
                direction="column"
                justify="space-evenly"
                alignItems="center"
            >
                <Grid item>
                    <ImagePreview
                        style={style}>
                        { returnValue.image === ""? (
                              <ImageGridItemContainer
                                  container
                                  direction="column"
                                  justify="center"
                                  alignItems="center"
                                  style={style}
                              >
                                  <PhotoCamera />

                              </ImageGridItemContainer>
                        ): (
                              <ImageWrapper>
                                  <Image src={returnValue.image}
                                         style={style}
                                  />


                              </ImageWrapper>
                        )

                        }
                    </ImagePreview>
                </Grid>
                <Grid item>
                    <InputWrapper
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                    >
                        <label htmlFor="filePicker">
                            <InputButton
                                variant="contained"
                                onClick={handleClick}
                                startIcon={<PhotoCamera/>}
                            >
                                Upload nyt billede
                            </InputButton>
                        </label>
                        <input type="file" id="filePicker" accept="image/*" style={{visibility: "hidden"}} ref={hiddenFileInput} onChange={onImageClick}/>
                    </InputWrapper>
                </Grid>
                <Grid item>
                    <TextField
                        fullWidth
                        label={title.toUpperCase()}
                        id="standard-size-normal"
                        size="medium"
                        defaultValue={returnValue.name}
                        onChange={(e: any) => onNameChange(e)}
                    />
                </Grid>
            </WrapperGrid>
            <ImageCrop
                dimensions={dimensions}
                image={returnValue.image}
                onCropped={onCroppedImage}
                open={cropOpen}
                circularCrop={isCircular}
            />
        </>
    )
}

export default ReviewIdentityEditor;
