import styled from 'styled-components';
import React from 'react';
import {
    Button,
    GridListTile,
    GridListTileBar
} from '@material-ui/core';
import {IBrand, ICategory} from '../../../Interfaces/IBrand';

interface BrandCardProps {
    brand: IBrand;
    onCardClicked: (id: string) => void;
}

const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const OpenBrandEditorButton = styled(Button)`
    width: 100%;
    height: 100%;
    padding: 0;
`;

const StyledGridListTile = styled(GridListTile)`
    width: 285px;
    margin: 6px;
    height: 190px;
    text-align: center;
`;

const EditableBrandCard = ({brand, onCardClicked}: BrandCardProps) => {
    const openBrandEditor = (id: string) => {
        onCardClicked(id);
    }
    return (
        <StyledGridListTile key={brand.image}>
            <OpenBrandEditorButton
                onClick={() => openBrandEditor(brand.id)}>
                <StyledImage
                    src={brand.image}>
                </StyledImage>
                <GridListTileBar
                    title={brand.name.toUpperCase()} />
            </OpenBrandEditorButton>
        </StyledGridListTile>
    )
}

export default EditableBrandCard;
