import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import {ICategory, IUpdateBrand, IBrand, IUpdatedBrand} from '../../../Interfaces/IBrand';
import {CategoriesList} from '../../../static/api_uris';
import BrandListPicker from './BrandListPicker';
import BrandEditor from './BrandEditor';
//import {auth} from '../Utils/Authentication';
import compareValues from '../Utils/CompareValues';
import {UpdateBrand} from '../../../static/api_uris';
import { VariantType, useSnackbar } from 'notistack';


const BrandManager = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [uploading, setUploading] = useState(false);
    var emptyUpdateBrand = { allCategories: categories as ICategory[], brand: {} as IBrand, inCategories: [] as ICategory[] } as IUpdateBrand
    const [updateBrand, setUpdateBrand] = useState<IUpdateBrand>(emptyUpdateBrand);
    const [brandEditorOpen, setBrandEditorOpen] = useState(false);

    const openBrandEditor = (brand?: IUpdateBrand) => {
        if (typeof brand === "undefined"){
            var newBrand:IUpdateBrand = {
                allCategories: categories,
                brand: {id: "", image: "", name: "", priority: 0 } as IBrand,
                inCategories: [] as ICategory[]
            }
            brand = newBrand;
        }
        setUpdateBrand(brand);
        setBrandEditorOpen(true);
    }

    const onBrandUpdate = (updatedBrand: IUpdatedBrand) => {
        const brand = updatedBrand.brand;
        const categoriesToBeCreated = updatedBrand.inCategories.filter(cat => cat.id === "").map(cat => cat.name);

        const updateBrandObject = {
            "brand": brand,
            "create_categories": categoriesToBeCreated,
            "added_to_categories": updatedBrand.inCategories.map(cat => cat.id).filter(cat => cat !== ""),
        };
        setUploading(true);
        var message = "";
        axios
            .post(UpdateBrand,updateBrandObject)//, auth())
            .then(response => {
                if (brand.id === ""){
                    message = "Brand er blevet oprettet";
                }
                else {
                    message = "Brand er blevet opdateret";
                }
                postUpdate(message, 'success')
                closeBrandUpdate();
                const categoriesData:ICategory[] = response["data"];
                setCategories(categoriesData);
            })
            .catch(error => {
                if (brand.id === ""){
                    message = "Brand er blevet ikke oprettet";
                }
                else {
                    message = "Brand er blevet ikke opdateret";
                }
                postUpdate(message, 'error')
            })
        .finally( () => setUploading(false))
    }
    const closeBrandUpdate = () => {
        console.log(categories);
        setBrandEditorOpen(false);
        setUpdateBrand(emptyUpdateBrand);
    }



    const fetchBrands = () => {
        axios
            .get<ICategory[]>(CategoriesList, {
                headers: {
                    "Content-Type": "application/json"
                },
            })
                .then(response => {
                    const categories = response["data"]
                    categories.sort(compareValues('name')) //Sort categories by name (maybe remove?)
                    categories.forEach(cat => cat.brands.sort(compareValues('name'))) //Sort brands in categories by name (keep)
                    setCategories(categories)
                    emptyUpdateBrand.allCategories = categories;
                })
                .catch(err => {
                    console.log("error fetching "+CategoriesList)
                    console.log(err)
                    postUpdate("Fejl skete da vi skulle hente brands. Reload siden for at prøve igen.", 'error')
                })
    }

    useEffect(() => {
        fetchBrands();
    }, [])

    const postUpdate = (message: string, variant: VariantType) => {
        enqueueSnackbar(message, { variant });
    }

    return (
        <div>
            <BrandListPicker
                categories={categories}
                openBrandEditor={openBrandEditor}
                loading={uploading}/>
            <BrandEditor
                open={brandEditorOpen}
                brand={updateBrand}
                handleBrandAddOrUpdate={onBrandUpdate}
                handleCancel={closeBrandUpdate}
            />
        </div>
    )
}

export default BrandManager;
