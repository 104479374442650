import React from 'react';
import Image from '../components/Image';
import styled from 'styled-components';
import {
    Grid,
    Typography
} from '@material-ui/core';

interface IFooter {
    LogoIcon: string;
}

const Wrapper = styled(Grid)`
    margin-top: 15px;
    background-color: ${props => props.theme.palette.primary.dark};
    height: 85px;
    color: ${props => props.theme.palette.primary.contrastText}
`;

const LogoContainer = styled.div`
    width: 110px;
    height: 34px;
    ${props => props.theme.breakpoints.up('md')} {
    width: 110px;
    height: 50px;
    }
`;
const StyledImage = styled(Image)`
    width: 100%;
    height: 100%;
`;

const BrandingTextWrapper = styled.div`

`;

const BrandingText = styled.p`
    margin: 5px;
    a {
        color: ${props => props.theme.palette.primary.contrastText};
    }
`;


const Footer = ({LogoIcon}:IFooter) => {
    return(
        <Wrapper container
                 direction="column"
                 justify="center"
                 alignItems="center">

            <LogoContainer>
                <a href="/" title="thefront">
                    <StyledImage
                        src={LogoIcon}
                        alt="Fashion For Business"
                        lazy={false}
                    />
                </a>
            </LogoContainer>
            <BrandingTextWrapper>
                <BrandingText>Lavet af <a href="https://blog.rostgaard.it" target="_blank">rostgaard.it</a></BrandingText>
            </BrandingTextWrapper>
        </Wrapper>
    )
}

export default Footer;
