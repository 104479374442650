import React, { useEffect, useState} from 'react';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import {
    Button,
    Card,
    Divider,
    CardContent,
    Grid,
    MenuItem,
    TextField,
    Typography
} from '@material-ui/core';
import { IOrder, IProductDetails } from '../../../Interfaces/IProduct';
import CheckoutDetails from '../Checkout/CheckoutDetails';
import ImageDisplay from './ImageDisplay';
import ColorDisplay from './ColorDisplay';
import { addOrder, getOrderList, removeOrder } from '../../utils/OrderStore';
import { VariantType, useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import axios from 'axios';
import { GetProductDetails } from '../../../static/api_uris';
import parse from 'html-react-parser';

interface IParameters{
    id: string;
    productId: string;
}

interface IOrderView {
    productDetails?: IProductDetails;
}

const Wrapper = styled(Grid)`
    height: 100%;
`;

const CardContentWrapper = styled(CardContent)`
    width: 100%;
`;

const CardWrapper = styled(Card)`
max-width: 550px;
min-width: 320px;
margin: 10px;
margin-left: 30px;
width: 100%;
@media (max-width: 600px){
    margin: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
}
`;

const OrderGrid = styled(Grid)`

`;

const CheckoutDetailsGrid = styled(Grid)`
    border-left: 1px solid black;
    margin-top: 0px;
`;

const DescriptionWrapper = styled.div`
    margin-top: 15px;
    margin-bottom: 15px;
`;

const OrderView = ({productDetails}: IOrderView) => {
    const parameters = useParams() as IParameters;
    const { enqueueSnackbar } = useSnackbar();
    var emptyOrder = {
        company_id: parameters.id,
        product_details: typeof productDetails === "undefined"? {} as IProductDetails : productDetails,
        color_id: "",
        gender: productDetails?.product.male? "Mand" : "Kvinde",
        size: "",
        order_number: ""
    } as IOrder
    const [product_Details, setProduct_Details] = useState<IProductDetails>(typeof productDetails === "undefined"? {} as IProductDetails : productDetails)
    const [order, setOrder] = useState<IOrder>(emptyOrder);
    const [existingOrders, setExistingOrders] = useState<IOrder[]>(getOrderList())
    const emptyProductDetails:IProductDetails = {
        images: [{color: {code:"", id:"", name: ""}, image: ""}],
        product: {
            brand: {id:"", image:"",name:"",priority:0},
            dessing_number: "", male: true, id: "", name: "", sizes: [{id: "", name: "", weight: 0}]
        }
    };

    const [pd, setPd] = useState<IProductDetails>(typeof productDetails === "undefined"? emptyProductDetails : productDetails);

    useEffect(() => {
        console.log("initial emptyOrder")
        console.log(emptyOrder)
        if (typeof productDetails === "undefined"){
            axios.get(GetProductDetails(parameters.id, parameters.productId))
                 .then(response => {
                     const data = response["data"] as IProductDetails
                     setPd(data);
                     setProduct_Details(data)
                     emptyOrder.product_details = data;
                     console.log("New empty order")
                     console.log(emptyOrder)
                     setOrder({
                         ...order,
                         product_details: data,
                         gender: data.product.male? "Mand" : "Kvinde"
                     })
                 })
                 .catch(err => {
                     console.log("Error fetching product with ID: "+ parameters.productId);
                     console.log(err);
                 });
        }
    },[productDetails])

    const postUpdate = (message: string,variant: VariantType) => {
        enqueueSnackbar(message, { variant });
    }

    const localRemoveOrder = (order:IOrder) => {
        setExistingOrders(removeOrder(order));
    }

    const addOrderToCookies = () => {
        var newOrder:IOrder = Object.assign({}, order);
        newOrder.order_number = uuidv4();
        // Append order to orders
        if (Object.keys(newOrder.product_details).length > 0){
        try {
            addOrder(newOrder);

            emptyOrder.product_details = product_Details;
            emptyOrder.gender = newOrder.gender;
            setOrder(emptyOrder);

            postUpdate("Vare tilføjet til din kurv", "success")

            const orderList = getOrderList()
            setExistingOrders(orderList);
        }
        catch(err){
            console.log("Failed to add order");
            postUpdate("Alle felter skal være udfyldt", "error")
        }
        }
        else{
            console.log("No product details.")
        }

    }

    const description = pd.product.description;

    return(
        <Wrapper
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
        >
            <Grid item sm={9}>
                <Link to={"/webshop/"+parameters.id}>
                    <Button
                        startIcon={<ArrowBackIcon />}
                        size="large"
                        variant="outlined"
                        style={{margin: '15px'}}
                    >
                        Tilbage
                    </Button>
                </Link>

                <CardWrapper elevation={0}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            <b>
                                {pd.product.name} | {pd.product.brand.name}
                                {pd.price? (<> - Pris: {pd.price} DKK</>):(<></>)}
                            </b>
                        </Typography>
                    </CardContent>
                    <OrderGrid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center">
                        <ImageDisplay images={pd.images} displayColor={true} showThumbnails={true} />
                        {description? (
                            <Grid container direction="column" alignItems="stretch" spacing={2}>
                                <Grid item>
                                    <Typography variant="h5">
                                        Beskrivelse:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Divider />

                                    <DescriptionWrapper>
                                        {parse(description.replace(/(?:\r\n|\r|\n)/g, '<br />'))}
                                    </DescriptionWrapper>
                                    <Divider />
                                </Grid>
                            </Grid>
                        ):<></>}
                        <CardContentWrapper>
                            <form noValidate autoComplete="off">
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="stretch"
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        container
                                        direction="row"
                                        justify="space-around"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <Grid item xs={6}>
                                            <TextField
                                                id="standard-select-size"
                                                select
                                                fullWidth
                                                required
                                                variant="outlined"
                                                label="Størrelse"
                                                value={order.size}
                                                onChange={(event:React.ChangeEvent<HTMLInputElement>) => setOrder({...order, size:event.target.value}) }
                                            >
                                                {pd.product.sizes.map((size) => (
                                                    <MenuItem key={size.id} value={size.id}>
                                                        {size.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="standard-select-gender"
                                                fullWidth
                                                label="Køn"
                                                required
                                                disabled
                                                variant="outlined"
                                                value={order.gender}
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="standard-select-color"
                                                select
                                                fullWidth
                                                variant="outlined"
                                                label="Farve"
                                                required
                                                value={order.color_id}
                                                onChange={(event:React.ChangeEvent<HTMLInputElement>) => setOrder({...order, color_id: event.target.value})}
                                            >
                                                {pd.images.map((img) => (
                                                    <MenuItem key={img.color.id} value={img.color.id}>
                                                        <ColorDisplay color={img.color} />
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            startIcon={<AddShoppingCartIcon />}
                                            onClick={addOrderToCookies}>
                                            Tilføj til kurv
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContentWrapper>
                    </OrderGrid>
                </CardWrapper>
            </Grid>
            <Grid item sm={3}>
                <CheckoutDetailsGrid
                    container
                    direction="column"
                    justify="center"
                    alignItems="stretch"
                    spacing={2}>
                    <Grid item>
                        <Typography variant="h5" style={{textAlign: "center"}}>
                            Kurv: ({existingOrders.length})
                        </Typography>
                    </Grid>
                    <Grid item>
                        <CheckoutDetails orders={existingOrders} removeOrder={localRemoveOrder} />
                    </Grid>
                    <Grid item style={{margin: '0 auto'}}>
                        <Link to={"/webshop/"+parameters.id+"/checkout"}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                startIcon={<ShoppingCartIcon />}
                                disabled={!(existingOrders.length > 0)}
                            >
                                Gå til checkout
                            </Button>
                        </Link>

                    </Grid>
                </CheckoutDetailsGrid>

            </Grid>
        </Wrapper>
    )
}

export default OrderView;
