export interface IPageContent {
  title: string;
  href: string;
  content_type: "link" | "button";
}
export interface IPage {
  title: string;
  id: string;
  pages: Array<IPageContent>
}

const pages: IPage = {
    title: 'Pages',
    id: 'pages',
    pages: [
      {
        title: 'Kontakt',
        href: '#contact',
        content_type: 'link'
      },
      {
        title: 'Brands',
        href: '#brands',
        content_type: 'link'
      },
      {
        title: 'Udtalelser',
        href: '#udtalelser',
        content_type: 'link'
      },
      {
        title: 'Login',
        href: '/login',
        content_type: 'button'
      }
    ],
};

export default pages;
