import TextField from '@material-ui/core/TextField';
import React from 'react';
import axios from "axios"
import { useForm } from "react-hook-form";
import ErrorMessageField from '../components/ErrorMessage';
import Button from '../components/Button';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';


interface ContactFormProps {
    first_name: string;
    last_name: string;
    email: string;
    company_name: string;
    message: string;
}

interface ContactFormComponentProps {
    updateSent: any;
    spacing: any; //TODO Get back to using a number. Compiler won't work atm
    endpoint: string;
    header: React.ReactNode;
}


const StyledForm = styled.form`
    padding: 5px;
`;

const StyledGrid = styled(Grid)`
    position: relative;
`;

const StyledButtonGrid = styled(Grid)`
    margin-top: 10px;
`;

const StyledGridItem = styled(Grid)`
`

const StyledButton = styled(Button)`
    color: ${props => props.theme.palette.primary.contrastText};
    background-color: ${props => props.theme.palette.primary.dark};
`;



const ContactForm = ({updateSent, spacing, endpoint, header}: ContactFormComponentProps) => {
    const [formData, setFormData] = React.useState<ContactFormProps>({
            first_name: "",
            last_name: "",
            company_name: "",
            email: "",
            message: ""
    });
    const [submitted, setSubmitted] = React.useState(false);
    const {
        register,
        handleSubmit,
        errors
    } = useForm<FormData>();

    React.useEffect(() => {
        if(submitted){
            setSubmitted(false);
            updateSent(true);
        }
    }, [submitted]);

    const formDataEffect = React.useEffect(() => {
        if (Object.values(formData).some(x => (x !== null && x !== "" && x !== ''))){
             axios
                 .post(endpoint, formData)
                 .then(data => {
                     setSubmitted(true);
                 })
                 .catch(err => {
                     console.log("Error");
                     console.log(err)
                 });
        }
    }, [formData])

    const onSubmit = handleSubmit((props: ContactFormProps)  => {
        console.log("HandleSubmit Pressed");
        if (props){
            if(Object.entries(errors).length === 0){
                setFormData(props)
            }
        }
    });

    return (
            <Grid container
                  direction="column"
                  justify="center"
                  alignItems="center">
                <Grid item>
                    {header}
                </Grid>
                <StyledGridItem item>
                    <StyledForm onSubmit={(onSubmit)}>
                        <Grid container
                              direction="column"
                              justify="center"
                              spacing={spacing}>
                            <Grid item xs={12}>
                                <Grid container
                                      direction="row"
                                      spacing={spacing}
                                      justify="center"
                                      alignItems="center"
                                      style={{maxWidth: "660px"}}
                                >
                                    <StyledGrid item xs={12} sm={6} md={6}>
                                        <TextField
                                            id="first_name"
                                            defaultValue={formData.first_name}
                                            name="first_name"
                                            inputRef={register({required: "Venligst skriv dit fornavn"})}
                                            label="Fornavn"
                                            variant="outlined"
                                            color="secondary"
                                            fullWidth
                                        />
                                        <ErrorMessageField
                                            errors={errors}
                                            name="first_name"/>
                                        <br />
                                    </StyledGrid>
                                    <StyledGrid item xs={12} sm={6} md={6}>
                                        <TextField
                                            id="last_name"
                                            defaultValue={formData.last_name}
                                            name="last_name"
                                            inputRef={register({required: "Venligst skriv dit efternavn"})}
                                            label="Efternavn"
                                            variant="outlined"
                                            color="secondary"
                                            fullWidth
                                        />
                                        <ErrorMessageField
                                            errors={errors}
                                            name="last_name"/>
                                    </StyledGrid>
                                    <StyledGrid item xs={12} sm={6} md={6}>
                                        <TextField
                                            id="email"
                                            defaultValue={formData.email}
                                            name="email"
                                            inputRef={
                                                register({
                                                    required: "Venligst skriv en email address",
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: "Forkert email format"
                                                    }
                                                })
                                            }
                                            label="Email"
                                            variant="outlined"
                                            color="secondary"
                                            fullWidth
                                        />
                                        <ErrorMessageField
                                            errors={errors}
                                            name="email"/>
                                    </StyledGrid>
                                    <StyledGrid item xs={12} sm={6} md={6}>
                                        <TextField
                                            id="company_name"
                                            defaultValue={formData.company_name}
                                            name="company_name"
                                            inputRef={register({required: "Venligst skriv dit firmanavn"})}
                                            label="Firma Navn"
                                            variant="outlined"
                                            fullWidth
                                            color="secondary"
                                        />
                                        <ErrorMessageField
                                            errors={errors}
                                            name="company_name"/>
                                    </StyledGrid>
                                </Grid>
                            </Grid>
                            <StyledGrid item xs={12}>
                                <TextField
                                    id="message"
                                    defaultValue={formData.message}
                                    name="message"
                                    inputRef={register({required: "Hovsa, du glemte vidst en besked"})}
                                    label="Besked"
                                    multiline
                                    variant="outlined"
                                    rows={4}
                                    fullWidth
                                    rowsMax={4}
                                    color="secondary"
                                />
                                <ErrorMessageField
                                    errors={errors}
                                    name="message"/>
                            </StyledGrid>
                        </Grid>
                        <StyledButtonGrid item>
                            <Grid container
                                  justify="flex-end"
                            >
                                <Grid item>
                                    <StyledButton type="submit" variant="contained">Kontakt mig</StyledButton>
                                </Grid>
                            </Grid>
                        </StyledButtonGrid>
                    </StyledForm>
                </StyledGridItem>
            </Grid>
    )
}

export default ContactForm;
