import {IPage} from '../../Interfaces/IPage';

const pages: IPage = {
    title: 'Pages',
    id: 'pages',
    pages: [
      {
        title: 'Brands',
        href: '/brands',
        content_type: 'link'
      },
      {
        title: 'Billeder',
        href: '/pictures',
        content_type: 'link'
      },
        {
          content_type: 'button',
          href: '/logout',
          title: 'Log ud'
      }

    ],
};

export default pages;
