import React from 'react';
import styled from 'styled-components';
import CHashLink from '../CHashLink';
import NavigationButton from './NavigationButton';
import {
    ListItem,
    Typography
} from '@material-ui/core';
import { IPageContent } from '../../Interfaces/IPage';

interface INavigationItem{
    page_content: IPageContent
}

// interface INavigationItem{
//     title: string;
//     href: string;
// }
//
const StyledListItemText: any = styled(Typography)`
    flex: 0 0 auto;
    white-space: nowrap;
    text-decoration: none;
    color: ${props => props.theme.palette.primary.contrastText};
    font-size: inherit;
`;

const StyledListItem: any = styled(ListItem)`
    cursor: pointer;
    padding-top: 0;
    padding-bottom: 0;
`;

const NavigationItem = ({page_content}: INavigationItem) => {
    return (
        <StyledListItem>
            <StyledListItemText
                variant="body1"
                color="inherit">
                {page_content.content_type === "link"? (
                    <CHashLink href={page_content.href} title={page_content.title} />
                ):(
                    <NavigationButton page_content={page_content}/>
                )}
            </StyledListItemText>
        </StyledListItem>
    )
}

export default NavigationItem;
