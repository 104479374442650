import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { ICategory } from '../Interfaces/IBrand';
import SmallListPicker from './SmallListPicker';
import LargeListPicker from './LargeListPicker';
import compareValues from './Dashboard/Utils/CompareValues';

interface ListPickerCardProps {
    endpoint: string;
}


const Wrapper = styled.div`
`;


const ListPickerCard = ({endpoint="categories/list/"} : ListPickerCardProps) => {
    const [data, setData] = React.useState<Array<ICategory>>([])

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    React.useEffect(() => {
        var uri = axios.defaults.baseURL + endpoint;
        axios
            .get<Array<ICategory>>(uri, {
                headers: {
                    "Content-Type": "application/json"
                },
            })
                .then(response => {

                    const categories = response["data"]
                    categories.sort(compareValues('name')) //Sort categories by name (maybe remove?)
                    categories.forEach(cat => cat.brands.sort(compareValues('name'))) //Sort brands in categories by name (keep)
                    setData(categories);
            })
            .catch(err => {
                console.log("Error fetching "+endpoint)
                console.log(err);
            })
    },[]);

    return (
        <Wrapper id="brands">
            {
                isMd?
                <LargeListPicker categories={data}/>
                :
                <SmallListPicker categories={data}/>
            }
        </Wrapper>
    )
}

export default ListPickerCard;
