import styled from 'styled-components';
import React from 'react';
import {
    GridListTile,
    GridListTileBar
} from '@material-ui/core';

interface BrandCardProps {
    name: string;
    image: string;
}

const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;


const StyledGridListTile = styled(GridListTile)`
    width: 285px;
    margin: 6px;
    height: 190px;
    text-align: center;
`;

const BrandCard = ({image, name}: BrandCardProps) => {
    return (
        <StyledGridListTile key={image}>
            <StyledImage
                src={image}>
            </StyledImage>
            <GridListTileBar
                title={name.toUpperCase()} />
        </StyledGridListTile>
    )
}

export default BrandCard;
