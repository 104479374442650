import React from 'react';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

interface ILoading {
    message: string;
}

const LoadingWrapper = styled.div`
    margin: 0 auto;
    text-align: center;
    padding: 30px;
    position: relative;
`;

const CircleTop = styled(CircularProgress)`
    color: #BAA897;
    animationDuration: 550ms;
    position: absolute;
`;

const CircleBottom = styled(CircularProgress)`
    strokeLinecap: round;
`;

const Loading = ({message}: ILoading ) => {
    return(
        <LoadingWrapper>
            <CircleTop
                variant="determinate"
                size={40}
                thickness={4}
                value={100}
            />
            <CircleBottom
                variant="indeterminate"
                disableShrink
                size={40}
                thickness={4}
            />
            <p>{message}</p>
        </LoadingWrapper>
    )
}

export default Loading;
