import React from 'react';
import styled from 'styled-components';
import { webshop_root } from '../../../static/api_uris';
import { Link } from 'react-router-dom';
import {
    Card,
    CardActionArea,
    CardContent,
} from '@material-ui/core';

interface IWebshop {
    image: string;
    name: string;
    id: string;
}

const StyledCard = styled(Card)`
    width: 400px;
    height: 270px;
`;

const ImageWrapper = styled.div`
    background-color: #29395a;
    padding: 10px;
    height: 150px
`;

const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;



const WebshopCard = ({image, name, id}:IWebshop) => {
    return (
        <StyledCard>
            <CardActionArea component={Link} to={webshop_root+id}>
                <ImageWrapper>
                    <StyledImage
                        alt={name}
                        src={image}
                        title={name}
                    />
                </ImageWrapper>
                <CardContent>
                    <h1>{name}</h1>
                </CardContent>
            </CardActionArea>
        </StyledCard>
    )
}

export default WebshopCard;
