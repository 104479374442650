import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { webshop_root } from '../../../static/api_uris';
import { IProduct, ISize, IColor } from '../../../Interfaces/IProduct';
import { Link, useLocation } from 'react-router-dom';
import { GetAllOrders } from '../../../static/api_uris';
import {
    GridList,
    Paper,
    Select,
    FormControl,
    InputLabel,
    MenuItem
} from '@material-ui/core';
import OrderTile from './OrderTile';
import axios from 'axios';
interface IOrder {
    gender: string;
    product: IProduct;
    size: ISize;
    image: { image: string; }
    color: IColor;
}

interface IBundle {
    pk: number;
    public_id: string;
    orders: IOrder[]
}
interface ICompany {
    id: string;
    company_image: string;
    company_name: string;
}
interface IBundleOrders {
    company: ICompany;
    orders: IBundle[]
}

const HeadlineWrapper = styled.div`
    width: 100%;
    text-align: center;
`

const BundledWrapper = styled.div`
    width: 100%;
`;

const CompanySelector = styled.div`
    width: 100%;
    margin: 0 auto;
    display: flex;
    padding-bottom: 60px;
`;

const OrdersWrapper = styled.div`
    width: 100%;
`;

const OrdersList = styled(Paper)`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px;
    border: 3px dashed;
    margin-bottom: 10px;

`;
const StyledFormControl = styled(FormControl)`
    text-align: center;
    min-width: 267px;
    margin: 0 auto;
`;


const OrderGrid = styled(GridList)`
    flex-wrap: nowrap;
    hejtranform translateZ(0);
`;
const StyledInputLabel = styled(InputLabel)`
    transform: translate(0, 1.5px) scale(1);
    width: 100%;
    font-size: 1.1rem;
`;

const StyledSelect = styled(Select)`
    font-size: 1.5rem;
`;

const CompanyWrapper = styled.div`
    height: 42px;
    width: 100%;
`

const CompanyImage = styled.img`
    height: 42px;
    width: 42px;
    obejct-fit: contain;
`

const BundledOrders = () => {
    const [allBundledOrders, setAllBundledOrders] = useState<IBundleOrders[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<IBundleOrders>({
        company: {company_image: "", company_name: "", id: ""},
        orders: []
    } as IBundleOrders);
    const [initialStateSet, setInitialState] = useState<boolean>(false);
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        fetchOrders()
    },[]);
    useEffect(() =>{
        if(!initialStateSet && allBundledOrders.length > 0 ){
            funcSetSelectedCompany(allBundledOrders[0].company.id)
            setInitialState(true)
        }
    },[allBundledOrders])
    const fetchOrders = () => {
        axios.get(GetAllOrders)
             .then(response => {
                 setAllBundledOrders(response["data"])
             })
             .catch(err => {
                 console.log("error orders")
                 console.log(err);
             })
    }

    const funcSetSelectedCompany = (company_id: string) => {
        var tmpSelectedCompany = allBundledOrders.find(obj => obj.company.id === company_id) ;
        if(tmpSelectedCompany){
            tmpSelectedCompany.orders = tmpSelectedCompany.orders.sort((a: IBundle, b:IBundle) => b.pk - a.pk);
            setSelectedCompany(tmpSelectedCompany);
        }
        else{
            console.log(`The company with ID: ${company_id} does not exists...`);
        }
    }
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        funcSetSelectedCompany(event.target.value as string);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <>
            <HeadlineWrapper>
                <h1>Orders</h1>
            </HeadlineWrapper>
            {allBundledOrders.length === 0? (
                <p>Loading</p>
            ) : (
                <BundledWrapper>
                    <CompanySelector>
                        <StyledFormControl>
                            <StyledInputLabel>
                                <StyledSelect
                                    labelId="demo-controlled-open-select-label"
                                    id="demo-controlled-open-select"
                                    open={open}
                                    onClose={handleClose}
                                    onOpen={handleOpen}
                                    value={selectedCompany.company.id}
                                    onChange={handleChange}>
                                    {
                                        allBundledOrders.map(bo => (
                                            <MenuItem value={bo.company.id}>
                                                {bo.company.company_name}
                                            </MenuItem>
                                        ))
                                    }
                                </StyledSelect>
                            </StyledInputLabel>
                        </StyledFormControl>
                    </CompanySelector>

                    <OrdersWrapper>
                        {selectedCompany.orders.map(bundledOrders => (
                            <OrdersList>
                                <u>Bestillings ID: <b>{bundledOrders.pk}</b> | Kundens offentlige ID: <b>{bundledOrders.public_id}</b></u>
                                <OrderGrid>
                                    {bundledOrders.orders.map(order =>(
                                        <OrderTile
                                            product={order.product}
                                            size={order.size}
                                            image={order.image}
                                            color={order.color}
                                            gender={order.gender}
                                        />
                                    ))}
                                </OrderGrid>
                            </OrdersList>

                        ))}
                    </OrdersWrapper>
                </BundledWrapper>
            )}
        </>
    )

}

export default BundledOrders;
