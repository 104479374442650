import React from 'react';
import styled from 'styled-components';
import {
  List,
  ListItem,
  Typography,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { IPage } from '../components/navigation/pages';
import NavigationItem from './navigation/NavigationItem';

interface SidebarNavProps {
    pages: IPage;
    onClose: () => void;
}

const StyledList = styled(List)`
    background-color: ${props => props.theme.palette.primary.dark};
    max-height: 100%;
    width: 100%;
    max-width: 400px;
    min-width: 200px;
    height: 100%;
    font-size: 22px;
`;

const ListItemCloseIcon: any = styled(ListItem)`
    justify-content: flex-end;
    cursor: pointer;
`;


const StyledListItemIcon = styled(ListItemIcon)`
    min-width: auto;
`;

const StyledListItem: any = styled(ListItem)`
    flex-direction: column;
    align-items: flex-start;
`;

const StyledLinkText: any = styled(Typography)`
    text-decoration: none;

`;

const SidebarNav = ({pages, onClose}: SidebarNavProps) => {
    return(
        <StyledList>
            <ListItemCloseIcon onClick={onClose}>
                <StyledListItemIcon>
                    <CloseIcon fontSize="small" />
                </StyledListItemIcon>
            </ListItemCloseIcon>
            {pages["pages"].map(page => (
                <NavigationItem page_content={page}/>
            ))}
        </StyledList>
    );
}

export default SidebarNav;
