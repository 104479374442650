import { createMuiTheme } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import purple from "@material-ui/core/colors/purple";

const primaryGreen = green[500];
const accentGreen = green.A200;
const darkGreen = green[900];
const primaryPurple = purple[500];
const accentPurple = purple.A200;
const darkPurple = purple[900];
const orange = "#BD7738";


const darkBlue = '#29395A';


const paletteTheme = createMuiTheme ({
  // name: 'Light Theme',
  palette: {
    backgroundColor: {
      main: "#FFFEFA"
    },
    primary: {
      light: accentGreen,
      main: orange,
      dark: darkBlue,
      contrastText: "#fff"
    },
    type: "light",
    secondary: {
      light: accentPurple,
      main: primaryPurple,
      dark: darkPurple,
      contrastText: "#000"
    },
    layout: {
      contentWidth: '1140px'
    },
    sectionSeparator: {
      height: 450,
      heightSm: 200,
      heightMd: 500,
      color: {
        main: darkBlue,
        contrastText: "#fff"
      }
    }
  },
  typography: {
    //fontFamily: 'Puritan',
    h2: {
      fontSize: '3.75rem',
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 400
    }
  }
});

export default paletteTheme;
