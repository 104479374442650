import React, {useState} from 'react';
import logo_dark from './stories/assets/company_icon_dark.png';
// import logo_light from './stories/assets/company_icon_light.png';
import logo_light from './stories/assets/new-logo.png';
import './App.css';
import Page from './stories/components/Page';
import Dashboard from './stories/components/Dashboard/Dashboard';
import Image from './stories/assets/tmp.jpg'
import LightTheme from './style/themes/light';
import {
  StylesProvider,
  ThemeProvider
} from "@material-ui/core";
import { ThemeProvider as SCThemeProvider } from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory,
} from 'react-router-dom';
import WebshopManager from './stories/components/Webshop/WebshopManager';
import { SnackbarProvider } from 'notistack';
import { AccountRefreshToken } from './stories/static/api_uris';
import axios from "axios";
import Login from './stories/components/Login/login';
import Logout from './stories/components/Login/logout';
import ForceLogout from './stories/components/Login/ForceLogout';

const url = process.env.REACT_APP_BACKEND_URL
axios.defaults.baseURL = url? url : "";
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

axios.defaults.withCredentials = true;

// no intercept axios instance to make sure that we don't end in an endless loop
const noInterceptAxios = axios.create();
noInterceptAxios.defaults.baseURL = url? url : "";
noInterceptAxios.defaults.xsrfCookieName = 'csrftoken';
noInterceptAxios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
noInterceptAxios.defaults.withCredentials = true;



function App() {
    let history = useHistory();
    var title = process.env.REACT_APP_TITLE
    title = title? title : "Loading...";

    axios.interceptors.response.use(response => {
        return response;
    }, async error => {
        const originalRequest = error.config;
        const is403 = error.response.status === 403;
        if ((is403 && originalRequest.url === originalRequest.baseURL+AccountRefreshToken) || (is403 && !originalRequest._retry)){
            originalRequest._retry = true;
            try{
                const response = await noInterceptAxios.post(AccountRefreshToken);
                const access_token = response["data"]["access_token"];
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
                return axios(originalRequest)
            }
            catch (err){
                console.log("ERROR")
                console.log(err.response);
                if(err.response){
                    if(err.response.status === 403 && err.response.data["detail"] === "No refresh token cookie"){
                        window.location.pathname = ('/forceLogout')
                        history.push('/forceLogout')
                    }
                }

            }
        }
        else {
            //console.log("failed to updating token")
            //store.remove('user');
            //window.location.reload();
            return Promise.reject(error);
        }
    })

    return (
        <>
        <HelmetProvider>
            <StylesProvider injectFirst>
                <ThemeProvider theme={LightTheme}>
                    <SCThemeProvider theme={LightTheme}>
                        <Helmet>
                            <title>{title}</title>
                        </Helmet>
                        <SnackbarProvider
                            maxSnack={1}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                        >
                            <Router>
                                <Switch>
                                    <Route
                                        exact path="/"
                                        render={() =>
                                            <Page
                                                image={Image}
                                                DarkLogo={logo_dark}
                                                LightLogo={logo_light}
                                            />
                                        }
                                    />
                                    <Route
                                        exact path="/dashboard">
                                        <Dashboard
                                            LightLogo={logo_light}/>
                                    </Route>
                                    <Route
                                        path="/webshop/:id?"
                                        component={WebshopManager} />
                                    <Route
                                        path="/login"
                                        component={Login} />
                                    <Route
                                        path="/logout"
                                        component={Logout} />
                                    <Route
                                        path="/forceLogout"
                                        component={ForceLogout} />
                                </Switch>

                            </Router>
                        </SnackbarProvider>
                    </SCThemeProvider>
                </ThemeProvider>
            </StylesProvider>
        </HelmetProvider>


        </>
    );
}

export default App;
