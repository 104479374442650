import React from 'react';
import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import { IColor } from '../../../Interfaces/IProduct';

interface IColorDisplay{
    color: IColor;
}

const ColorSquare = styled.div`
    border: 1px solid black;
    width: 20px;
    height: 20px;
`;

const ColorDisplay = ({color}:IColorDisplay) => {
    return(
        <Grid container spacing={3} direction="row" justify="center" alignItems="center">
            <Grid item xs={2}>
                <ColorSquare style={{backgroundColor: color.code}} />
            </Grid>
            <Grid item xs={10}>
                <Typography variant="body2" color="textSecondary" component="p">
                    {color.name}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default ColorDisplay;
