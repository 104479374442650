import React, { useEffect, useState } from 'react';
import RenderPhotos from '../SectionSeparators/PhotoSection/PhotoRender';
import {IImageList, ITextImagesArray} from '../../Interfaces/IImages';
import axios from 'axios';
import PhotoSection from '../SectionSeparators/PhotoSection/PhotoSection';
import {ImagesArray} from '../../static/api_uris';
import PhotoPlacementEditor from './PhotoEditor/PhotoPlacementEditor';

const PhotoSectionEditor = () => {
     const [images, setImages] = useState<IImageList>({
        image_list: [],
        loaded: false
    });
    useEffect(() => {
        const imageId = process.env.REACT_APP_UNIQUE_ID;
        const imgId = imageId? imageId : "";
        const uri = ImagesArray+imgId;
        axios
            .get(uri)
            .then(data => {
                const returnData = data["data"] as Array<ITextImagesArray>;
                var images:IImageList = {
                    image_list: returnData,
                    loaded: true
                };
                setImages(images as IImageList);
            })
            .catch(err => {
                setImages({
                    id: "",
                    image_list: [] as Array<ITextImagesArray>,
                    loaded: true
                } as IImageList)
                console.log("Error");
                console.log(err);
            })
    }, []);

    return(
        <RenderPhotos
            image_list={images.image_list}
            loaded={images.loaded}
            renderEmpty={true}
        >
            <PhotoPlacementEditor
                images={images.image_list} />
        </RenderPhotos>
    )

}

export default PhotoSectionEditor;
