import React, { useState, useRef, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@material-ui/core';
import styled from 'styled-components';
import { INewImage } from '../../../Interfaces/IImages';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

interface IImageCrop{
    open: boolean;
    selectedImage: INewImage;
    onCropped: (img: INewImage) => void;
}

interface CompletedCrop {
  x: number;
  y: number;
  width: number;
  height: number;
  unit: string;
  aspect: number;
}

const ImageCrop = ({open, selectedImage, onCropped}: IImageCrop) => {
    // Explicitely write the object.
    const selectedImg:INewImage = {
        index: selectedImage.index,
        dimensions: selectedImage.dimensions,
        text: selectedImage.text,
        image: selectedImage.image,
        id: selectedImage.id
    };
    // Set dimensions fallback
    const dimensions = selectedImg.dimensions? selectedImg.dimensions : [1,1];
    //Create the HTMLImageElement from the string image we get.
    const imageRef = new Image();
    const tmpImgUrl =  selectedImg.image?.dataURL;
    const imgUrl = tmpImgUrl? tmpImgUrl : ""
    imageRef.src=imgUrl? imgUrl : ""
    const imgRef = useRef<HTMLImageElement>(imageRef);
    //Construct the canvas we download the image from later
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    // This is the image we return. It's the cropped image result
    const [croppedImage, setCroppedImage] = useState("");
    const [completedCrop, setCompletedCrop] = useState<CompletedCrop>();
    const [crop, setCrop] = useState<Crop>({ unit: '%', width: 100, aspect: dimensions[0] / dimensions[1] });

    const [isOpen, setIsOpen] = useState(open);
    //const [img, setImg] = React.useState<INewImage>(image);

    const handleClose = () => {
        selectedImg.image.dataURL = croppedImage;
        onCropped(selectedImg);
        setIsOpen(false);
    };

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
        const pixelRatio = window.devicePixelRatio;

        const width = crop.width || 0;
        const height = crop.height || 0;
        canvas.width = width * pixelRatio;
        canvas.height = height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";

        const cropC = crop as CompletedCrop;
        console.group("Image scaling details")
        console.log("crop.x: " + crop.x + "| scaleX: " + scaleX)
        console.log("crop.y: " + crop.y + "| scaleY: " + scaleY)
        console.log("crop.width: " + crop.width);
        console.log("crop.height: "+ crop.height)
        console.groupEnd();
        ctx.drawImage(
            image,
            cropC.x * scaleX,
            cropC.y * scaleY,
            cropC.width * scaleX,
            cropC.height * scaleY,
            0,
            0,
            cropC.width,
            cropC.height
        );
        const cv = previewCanvasRef.current as HTMLCanvasElement;
        setCroppedImage(cv.toDataURL("image/png"));
    }, [completedCrop]);


    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            disableBackdropClick={true}
            disableEscapeKeyDown={true}>
            <DialogTitle id="form-dialog-title">Tilføj ny gruppe af billeder</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <ReactCrop
                        src={imgUrl}
                        crop={crop}
                        onChange={(c) => setCrop(c)}
                        onComplete={(c) => setCompletedCrop(c as CompletedCrop)}/>
                </DialogContentText>
<canvas
                ref={previewCanvasRef}
                style={{
                    width: Math.round(completedCrop?.width ?? 0),
                    height: Math.round(completedCrop?.height ?? 0),
                    maxWidth: '200px',
                    maxHeight: '200px',
                    display: 'none'
                }}
            />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    OK
                </Button>
            </DialogActions>

        </Dialog>
    )
}

export default ImageCrop;
