import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ProductCard from './ProductCard';
import { IProduct, IColor, IProductDetails } from '../../../Interfaces/IProduct';
import { IBrand } from '../../../Interfaces/IBrand';
import {
    Grid,
    Typography,
    Select,
    MenuItem
} from '@material-ui/core';
import axios from 'axios';
import { GetProductList } from '../../../static/api_uris';

interface IProductManager {
    webshopId: string;
}

const Wrapper = styled(Grid)`
    padding: 20px;
    width: 100% !important;
`;

const ProductManager = ({webshopId}:IProductManager) => {
    const [products, setProducts] = useState<IProductDetails[]>([]);
    const [filteredProducts, setFilteredProducts] = useState<IProductDetails[]>([]);
    const [gender, setGender] = useState('all');
    useEffect(() => {
        if (typeof webshopId !== "undefined"){
            axios.get(GetProductList(webshopId))
                 .then(response => {
                     const data = response["data"]
                     console.log("response")
                     console.log(data)
                     setProducts(data);

                 })
                 .catch(err => {
                     console.log("Error");
                     console.log(err)
                 })
        }
    },[webshopId])
    useEffect(() => {
        filterProducts()
    },[products, gender])


    const filterProducts = () => {
        if(gender==="male"){
            setFilteredProducts(products.filter(product => product.product.male))
        }
        else if(gender==="female"){
            setFilteredProducts(products.filter(product => !product.product.male))
        }
        else{
            setFilteredProducts(products);
        }
    }

  const handleChange = (event: any) => {
    setGender(event.target.value);
  };
    return(
        <Wrapper
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={3}
        >
            <Grid item xs={12}>
                <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={gender}
          onChange={handleChange}
        >
          <MenuItem value={'all'}>Alle</MenuItem>
          <MenuItem value={'male'}>Mand</MenuItem>
          <MenuItem value={'female'}>Kvinde</MenuItem>
        </Select>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h3">
                    Produkter
                </Typography>
            </Grid>
            {filteredProducts.map(product =>(
            <Grid item>
                <ProductCard productDetails={product} />
            </Grid>
            ))}
        </Wrapper>
    )
}

export default ProductManager;
