import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styled from 'styled-components';


interface ILazy {
    width: string;
    height: string;
}

interface IImage {
    src: string;
    alt: string;
    lazy: boolean;
    lazyProps: ILazy;
}

const StyledLazyImage = styled(LazyLoadImage)`
    width: 100%;
    height: 100%;
    display: block;
`;

const StyledImage = styled.img`
    display: block;
    width: 100%;
`;



const Image = ({src, alt, lazy, lazyProps}: IImage) => {
    if (lazy){
        return (
            <StyledLazyImage
                alt={alt}
                src={src}
                effect="opacity"
                {...lazyProps}
            />
        )
    }

    return (
        <StyledImage
            alt={alt}
            src={src}
        />
    )
}

Image.defaultProps = {
    lazy: true,
    lazyProps: {
        width: 'auto',
        height: 'auto'
    },
};

export default Image;
