import React from 'react';
import styled from 'styled-components';
import EditableBrandCard from './EditableBrandCard';
import {
    Grid,
    GridList,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
} from '@material-ui/core';
import Headline from '../../Headline';
import {
   Paper
} from '@material-ui/core';
import {IBrand, ICategory, IUpdateBrand} from '../../../Interfaces/IBrand';
import AddButton from '../AddButton';
import Loading from '../../utils/Loading';

interface ISmallListPicker{
    categories: Array<ICategory>;
    loading?: boolean;
    openBrandEditor: (brand?: IUpdateBrand) => void;
}

const LoadingWrapper = styled.div`
    padding-top: 30px !important;
    height: 130px !Important;
    display: ruby;
`;


const CardHead = styled.div`
    width: 100%;
    margin: 0 auto;
    display: flex;
    padding-bottom: 35px;
    padding-top: 25px;
`;

const StyledFormControl = styled(FormControl)`
    text-align: center;
    min-width: 267px;
    margin: 0 auto;
`;

const BrandGrid = styled(GridList)`
    flex-wrap: nowrap;
    hejtranform translateZ(0);
`;

const StyledPaper = styled(Paper)`
    width: 100%;
    padding-bottom: 86px;
`;

const StyledInputLabel = styled(InputLabel)`
    transform: translate(0, 1.5px) scale(1);
    width: 100%;
    font-size: 1.1rem;
`;

const StyledSelect = styled(Select)`
    font-size: 1.5rem;
`;

const StyledGrid = styled(Grid)`
    overflow-x: hidden;
`;

const BrandListPicker = ({categories, openBrandEditor, loading}: ISmallListPicker) => {
    const [selectedCategory, setSelectedCategory] = React.useState<string | string>("Ingen kategorier")
    const [selectedBrands, setSelectedBrands] = React.useState<Array<IBrand>>([])
    const [open, setOpen] = React.useState(false);
    const isInitialMount = React.useRef(true);

    React.useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            setSelectedCategory(categories[0]["name"])
            setSelectedBrands(categories[0]["brands"])
        }
    }, [categories]);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedCategory(event.target.value as string);
        const brandsList = categories.find(item => item["name"] === event.target.value);
        if (brandsList){
            setSelectedBrands(brandsList["brands"]!)
        }
        else{
            setSelectedBrands([])
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const onOpenBrandEditor = (id?: string) => {
        if(typeof id !== "undefined"){
            const brands = selectedBrands.filter(brand => brand.id === id);
            const selectedBrand:IBrand = brands[0];
            var inCategory = [];
            for (var cat of categories){
                const tmp = cat.brands.filter(brand => brand.id === id);
                if(tmp.length > 0){
                    console.log("HELLO");
                    inCategory.push(cat)
                }
            }

            const brandToUpdate:IUpdateBrand = {
                allCategories: categories,
                brand: selectedBrand,
                inCategories: inCategory
            };
            openBrandEditor(brandToUpdate);
        }
        else{
            openBrandEditor();
        }
    }

    return (
            <StyledGrid container
                  direction="column"
                  alignItems="center"
            >
                <Headline title="Brands"/>
                <StyledPaper elevation={0}>
                    <CardHead>
                        <StyledFormControl>
                            <StyledInputLabel id="demo-controlled-open-select-label">SE VORES BRANDS FOR:</StyledInputLabel>
                            <StyledSelect
                                labelId="demo-controlled-open-select-label"
                                id="demo-controlled-open-select"
                                open={open}
                                onClose={handleClose}
                                onOpen={handleOpen}
                                value={selectedCategory}
                                onChange={handleChange}>
                                {
                                    categories.map(d =>
                                        (
                                            <MenuItem value={d["name"]}>{d["name"].toUpperCase()}</MenuItem>
                                        )
                                    )
                                }
                            </StyledSelect>
                        </StyledFormControl>
                    </CardHead>
                    <BrandGrid>

                        {selectedBrands.map(brand => (
                                    <EditableBrandCard
                                        brand={brand}
                                        onCardClicked={onOpenBrandEditor}
                                    />
                            )
                        )}
                        {typeof loading === "undefined"? false : loading? (
                                <LoadingWrapper>
                                    <Loading message="Opdaterer brand..." />
                                </LoadingWrapper>
                            ) : (
                                <></>
                            )}
                        <AddButton openEditor={onOpenBrandEditor} title={"Tilføj nyt brand"}/>
                    </BrandGrid>
                </StyledPaper>
            </StyledGrid>
    )
}

export default BrandListPicker;
