import React from 'react';
import styled from 'styled-components';
import {
    GridListTile,
} from '@material-ui/core';
import { INewImage, ITextImage, ITextImagesArray} from '../../../Interfaces/IImages';
import PhotoGrid from '../../SectionSeparators/PhotoSection/PhotoGrid';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DroppableProvided,
} from "react-beautiful-dnd";
import AddImages from './AddImages';
import axios from 'axios';
import {ImagesCollectionUpdate, ImagesCollectionDelete, ImagesCollectionAdd, ImagesArrayReorder } from '../../../static/api_uris';
import store from 'store';
import Loading from '../../utils/Loading';
import ManagePhotoCollection from './ManagePhotoCollection';
import { VariantType, useSnackbar } from 'notistack';

interface IImageArray {
    images: Array<ITextImagesArray>;
}
interface IReorder {
    id: string;
    order: number;
}

const StyledGridListTile = styled(GridListTile)`
    height: 100% !important;
    width: auto !important;
`;

const StyledButtonGridListTile = styled(StyledGridListTile)`
    -ms-transform: translateY(100%);
    transform: translateY(100%);
    padding-right: 25px;
    padding-left: 15px;
`;

const AddButtonWrapper = styled.div`
    padding: 15px;
`;

const LoadingWrapper = styled.div`
    padding-top: 150px;
    display: ruby;
`;

const reorder = (list: any[], startIndex: number, endIndex:number): any => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};



const getItemStyle = (isDragging: boolean, draggableStyle: any): object => ({
    userSelect: 'none',
    background: isDragging ? '#0000003d' : 'inherit',
    maxHeight: 'inherit',
    ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean): object => ({
    display: 'flex',
    overflow: 'auto',
    listStyleType: 'none',
});

interface IManageCollectionState {
    open: boolean;
    images: INewImage[];
    collection: ITextImagesArray;
}

const PhotoSection = ({images}:IImageArray) => {
    const { enqueueSnackbar } = useSnackbar();
    const [imageList, setImageList] = React.useState<Array<ITextImagesArray>>(images);
    const imageId = process.env.REACT_APP_UNIQUE_ID;
    const imgId = imageId? imageId : "";
    const [uploading, setUploading] = React.useState(false);
    const [manageCollectionState, setManageCollectionState] = React.useState<IManageCollectionState>({
        open: false,
        images: [],
        collection: {} as ITextImagesArray
    });

    const onRemoveImage = (id: string) => {
        const uri = ImagesCollectionDelete + id+"/";
        axios
            .post(uri)
            .then(data => {
                const newState = imageList.filter(img => img.id !== id);
                newState.forEach((item, index) => item.order=index);
                postUpdate("Billedet er fjernet", 'info');
                setImageList(newState);
            })
            .catch(
                err => {
                    console.log("error removing images");
                    console.log(err);
            })
    }

    const transformImageListToNewImagesList = (existingImages: ITextImage[]): INewImage[] => {
        var newImageArray:INewImage[] = [];

        for(var i = 0; i < existingImages.length; i++){
            const img:ITextImage = existingImages[i];
            const newImage:INewImage = {
                image: {
                    dataURL: img.image,
                },
                index: i,
                id: img.id,
                text: img.text
            }
            newImageArray.push(newImage);
        }
        return newImageArray;
    }

    const handleCloseManagePhotoCollection = () => {
        setManageCollectionState({
            open: false,
            images: [],
            collection: {} as ITextImagesArray
        });
    }

    const updateImages = (updatedImages:ITextImagesArray) => {
        const uri = ImagesCollectionUpdate;
        // Find the index of the image collection we want to update
        const index = imageList.findIndex(img => img.id === manageCollectionState.collection.id);

        const updatedCollection = manageCollectionState.collection;

        updatedCollection.text_images = updatedImages.text_images;
        handleCloseManagePhotoCollection();
        // Only update images where image is base64 (meaning it's a newly added image)
        const imagesToUpdate = updatedImages.text_images;
        axios
            .patch(uri, imagesToUpdate)//, authentication())
            .then(data => {
                console.log("success");
                console.log(imagesToUpdate);
                imageList[index] = updatedCollection;
                setImageList(imageList);
            })
            .catch(err => {
                console.log("error updating");
                console.log(err);
            })

    }

    const handleImageCollection = (handleImages:ITextImagesArray) => {
        //TODO: Handle so this also can add images
        const tmp:ITextImagesArray = handleImages;

        // Get the uri used to update
        if(typeof manageCollectionState.collection.id === "undefined" ){
            addImages(handleImages)
        }
        else{
            updateImages(handleImages);
        }
    }

    const onEditImage = (id: string) => {
        const collection = imageList.find(img => img.id === id);
        const newImageArray: INewImage[] = transformImageListToNewImagesList(collection? collection.text_images : []);
        setManageCollectionState({
            open: true,
            images: newImageArray,
            collection: collection? collection : {} as ITextImagesArray
        });

    }

    const addImages = (newImages: ITextImagesArray) => {
        const uri = ImagesCollectionAdd;
        newImages.order = imageList.length;
        //newImages.text_images.forEach(img => img.text = "dette er en nyyyy test");
        newImages.imageListId = imgId;
        console.log(newImages);
        handleCloseManagePhotoCollection();
        setUploading(true)
        axios
            .post(uri,newImages)
            .then(data => {
                const newImageFromServer:ITextImagesArray = data["data"];
                //update state
                setImageList([...imageList, newImageFromServer]);
                //post feedback update
                postUpdate("Billederne er blevet tilføjet!", 'success');
            })
            .catch(err => {
                console.log("Error uploading images")
                console.log(err)
                postUpdate("Billederne blev desværre ikke uploadet. Prøv igen eller kontakt mrostgaard@protonmail.com hvis dette fortsætter.", "error")
            })
            .finally(() => setUploading(false));
    }

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }
        const uri = ImagesArrayReorder;
        const imgList = reorder(
            imageList,
            result.source.index,
            result.destination.index
        );
        //Set new position on the order for the element
        imgList.forEach((item: ITextImagesArray, index:number) => {
            item.order=index
        });
        //Create the element
        const returnArray:IReorder[] = imgList.map((item: ITextImagesArray) => {
            const returnImg:IReorder = {
                id: item.id? item.id : "",
                order: item.order
            }
            return returnImg;
        })
        axios
            .patch(uri, returnArray)//, authentication())
            .then(data => {
                postUpdate('Billedernes placering er opdateret', 'success')
            })
            .catch(err => {
                postUpdate('Billederenes placering blev ikke gemt', 'error')
                console.log(err)
            })

        setImageList(imgList);
    }

    const addNewImages = () => {
        setManageCollectionState({
            open: true,
            images: [],
            collection: {} as ITextImagesArray
        })
    }

    const postUpdate = (message: string,variant: VariantType) => {
        enqueueSnackbar(message, { variant });
    }


    return(
        <>
            {imageList.length > 0? (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                    {(provided: DroppableProvided, snapshot: any) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            {...provided.droppableProps}
                        >
                            {imageList.map((item: ITextImagesArray, index:number) => (
                                <Draggable key={item.order.toString()} draggableId={item.order.toString()} index={index}>
                                    {(provided: any, snapshot:any) => (
                                        <StyledGridListTile
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >

                                            <PhotoGrid images={item} key={index} onImageRemove={onRemoveImage} onImageCollectionUpdate={onEditImage}/>
                                        </StyledGridListTile>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                            {uploading? (
                                <LoadingWrapper>
                                    <Loading message="Uploader billeder..." />
                                </LoadingWrapper>
                            ) : (
                                <></>
                            )}
                            <StyledButtonGridListTile>
                                <AddImages onAddImages={addNewImages}/>
                            </StyledButtonGridListTile>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            ):(
                <AddButtonWrapper>
                    <AddImages onAddImages={addNewImages}/>
                </AddButtonWrapper>
            )}
            <ManagePhotoCollection
                isOpen={manageCollectionState.open}
                headline="Opdater billeder"
                description="Put nye billeder ind i den her collection ved at klikke på et billede."
                images={manageCollectionState.images}
                handleCancel={handleCloseManagePhotoCollection}
                handleOkClose={handleImageCollection}
                calledBy="PhotoPlacementEditor"
            />
        </>
    )
}

export default PhotoSection;
