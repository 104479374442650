import React from 'react';
import styled from 'styled-components';
import { IImage } from '../../../Interfaces/IProduct';
import {
    Grid
} from '@material-ui/core';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ColorDisplay from './ColorDisplay';

interface IImageDisplay{
    images: IImage[];
    displayColor: boolean;
    showThumbnails: boolean;
}

const Wrapper = styled.div`
`;

const SlideWrapper = styled.div`
`;

const ImageWrapper = styled.div`
`;

const ImageColorWrapper = styled(Grid)`

`;

const ImageColor = styled.div`
    width: 135px;
    margin: 0 auto;
`;

const CarouselWrapper = styled(Carousel)`
    >.carousel {
        >.control-arrow {
            background: rgba(0,0,0,0.2);
        }
    }
`;

const ImageDisplay = ({images, displayColor,showThumbnails}:IImageDisplay) => {
    return(
        <Wrapper>
            <SlideWrapper>
                <CarouselWrapper
                    autoPlay={false}
                    infiniteLoop={true}
                    showStatus={false}
                    showIndicators={false}
                    showThumbs={showThumbnails}
                >
                    {images.map((img, index) => (
                        <ImageWrapper key={index}>
                            <img src={img.image} alt={img.color.name}/>
                            {displayColor? (

                                    <ImageColor>
                                        <ColorDisplay color={img.color} />
                                    </ImageColor>
                            ): (<></>)}
                        </ImageWrapper>
                    ))}
                </CarouselWrapper>

            </SlideWrapper>
        </Wrapper>
    )
}

export default ImageDisplay;
