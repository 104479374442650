import React from 'react';
import styled from 'styled-components';
import {
    GridListTile,
    GridListTileBar
} from '@material-ui/core';
import { IProduct, ISize, IColor } from '../../../Interfaces/IProduct';

interface IOrder {
    gender: string;
    product: IProduct;
    size: ISize;
    color: IColor;
    image: { image: string; }
}

const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;


const StyledGridListTile = styled(GridListTile)`
    width: 285px;
    margin: 6px;
    height: 190px;
    text-align: center;
    border: 1px solid;
`;

const OrderTile = ({product, size, image, color, gender}: IOrder) => {
    const title = `'${product.name}'' - ${size.name} | '${product.dessing_number}'`
    return (
        <StyledGridListTile key={product.id}>
            <StyledImage
                src={image.image}>
            </StyledImage>
            <GridListTileBar
                title={title} />
        </StyledGridListTile>
    )
}

export default OrderTile;
