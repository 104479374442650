import React from 'react';
import ConfirmationDialog from '../Dashboard/Utils/ConfirmationDialog';
import { logoutAction } from '../Dashboard/Utils/Authentication';
import { useHistory } from "react-router-dom";

const Logout = () => {
    let history = useHistory();
    const localLogoutAction = () => {
        logoutAction();
        history.push('/login')
    }

    return(
        <>
            <ConfirmationDialog
                open={true}
                headline="Du bliver logget ud"
                body="Dit login er blevet for gammelt. Log venligst ind igen."
                okAction={localLogoutAction}
                okString="Forstået"
            />
        </>
    )
}

export default Logout;
