import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { IPageContent } from '../../Interfaces/IPage';
import { Button } from '@material-ui/core';

interface INavigationButton {
    page_content: IPageContent;
}

const StyledButton = styled(Button)``;

const NavigationButton = ({page_content} :INavigationButton) => {
    return (
        <Link to={page_content.href}>
            <StyledButton variant="contained" color="primary">
                {page_content.title}
            </StyledButton>
        </Link>
    )
}

export default NavigationButton;
