import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from '@material-ui/core';
import {INewImage} from '../../../Interfaces/IImages';

interface IImageTextEditor {
    image: INewImage;
    open: boolean;
    onReturn: (returnText: string) => void;
    onHandleCancel: () => void;
}

const ImageTextEditor = ({image, open, onReturn, onHandleCancel}:IImageTextEditor) => {
    const [text, setText] = React.useState(image.text? image.text : "");
    const handleCancel = () => {
        onHandleCancel()
    };

    const handleOk = () => {
        onReturn(text);
    }

    return(
        <Dialog open={open} onClose={handleCancel} >
            <DialogTitle id="form-dialog-title">Tilføj tekst</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Tilføj ny text eller opdater teksten til dit billede
                </DialogContentText>
                <TextField
                    onChange={e => setText(e.target.value)}
                    autoFocus
                    margin="dense"
                    label="Tekst"
                    value={text}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleOk} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ImageTextEditor;
