import React from 'react';
import { Drawer } from '@material-ui/core';
import SidebarNav from './SidebarNav';
import styled from 'styled-components';
import { IPage } from './navigation/pages';


interface ISidebar{
    onClose: () => void;
    pages: IPage;
    open: boolean;
    variant: string;
}

const StyledDrawer: any = styled(Drawer)`
    max-width: 270px;
    height: 100%;
`;

const StyledDiv = styled.div`
    height: 100%;
    padding: ${props => props.theme.spacing(1)};
    display: contents;
`;

const StyledSidebarNav = styled(SidebarNav)`
    margin-bottom: ${props => props.theme.spacing(1)};
`;

const Sidebar = ({onClose, pages, open, variant}: ISidebar) => {
    return (
        <StyledDrawer
            anchor="left"
            onClose={onClose}
            open={open}
            variant={variant}
        >
            <StyledDiv>
                <StyledSidebarNav pages={pages} onClose={onClose} />
            </StyledDiv>
        </StyledDrawer>
    );
}

export default Sidebar;
