import React from 'react';
import ConfirmationDialog from '../Dashboard/Utils/ConfirmationDialog';
import { logoutAction } from '../Dashboard/Utils/Authentication';
import { useHistory } from "react-router-dom";

const Logout = () => {
    let history = useHistory();

    const localLogoutAction = () => {
        logoutAction();
        history.push('/')
    }
    const cancelAction = () => {
        history.goBack();
    }
    return(
        <><ConfirmationDialog
              open={true}
              headline="Log ud"
              body="Er du sikker på at du vil logge ud?"
              okAction={localLogoutAction}
              cancelAction={cancelAction}
            />
        </>
    )
}

export default Logout;
