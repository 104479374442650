import React, {useState, useEffect} from 'react';
import AddButton from '../AddButton';
import styled from 'styled-components';
import { ITestimonial } from '../../../Interfaces/ITestimonial';
import {TestimonialsList, TestimonialsUpdateOrCreate, TestimonialsReorder, TestimonialDelete} from '../../../static/api_uris';
import axios from 'axios';
import TestimonialCard from '../../Testimonials/TestimonialCard';
import Headline from '../../Headline';
import ReviewEditor from './ReviewEditor';
import {
    Button,
    GridList,
    GridListTile,
} from '@material-ui/core';
import { DragDropContext, Droppable, Draggable, DroppableProvided } from "react-beautiful-dnd";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
//import {auth} from '../Utils/Authentication';
import Loading from '../../utils/Loading';
import { VariantType, useSnackbar } from 'notistack';

const Wrapper = styled.div`
    margin-bottom: 50px;
`;

const HeadlineWrapper = styled.div`
    text-align: center;
`;

const LoadingWrapper = styled.div`
    padding-top: 150px;
    display: ruby;
`;

const DragDropContextWrapper = styled.div`
    height: 500px;
    border: 1px solid;
`;

const StyledDragDropContext = styled(DragDropContext)`
    height: inherit !important;
`;

const StyledAddButton = styled(AddButton)`
    margin-top: 140px !important;
    border: 1px solid;
`;

const SelectButton = styled(Button)`
    height: 100% !important;

`;

const StyledGridListTile = styled(GridListTile)`
    height: 100% !important;
    width: 430px !important;
`;

const Actions = styled.div`
    z-index: 5;
`;

const DeleteButton = styled(Button)`
    position: absolute;
    right: 5px;
    top: 1px;
    width: 40px !important;
    height: 40px !important;
    background-color: white;
    padding: 0;
    border-radius: 50%;
    border: 3px solid black;
    min-width: 40px !important;
    &:hover {
        background-color: #c3c3c3;
    }
`;

const EditButton = styled(DeleteButton)`
    right: 50px;
`;


const reorder = (list: any[], startIndex: number, endIndex:number): any => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const getItemStyle = (isDragging: boolean, draggableStyle: any): object => ({
    userSelect: 'none',
    background: isDragging ? '#0000003d' : 'inherit',
    maxHeight: '100%',
    ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean): object => ({
    display: 'flex',
    overflow: 'auto',
    listStyleType: 'none',
    height: '480px',
});

interface IReorder {
    id: string;
    order: number;
}

const ReviewManager = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [reviews, setReviews] = useState<ITestimonial[]>([]);
    const [editorOpen, setEditorOpen] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [selectedReview, setSelectedReview] = useState<ITestimonial>({
        company_image: "",
        company_name: "",
        id: "",
        reviewer_image: "",
        reviewer_name: "",
        text: ""
    } as ITestimonial);
    //Fetch all the testimonials
    useEffect(() => {
        axios
            .get<ITestimonial[]>(TestimonialsList)
                .then(response => {
                    const responseData = response["data"]
                    setReviews(responseData)
                })
                .catch(err => {
                    console.log("Error fetching testimonials")
                    console.log(err);
                })
    },[])

    const openReviewEditor = () => {
        setEditorOpen(true);
    }

    const cancelReviewEditor = () => {
        setEditorOpen(false);
    }

    const reviewUpdated = (testimonial:ITestimonial) => {
        console.log("Testimonial");
        console.log(testimonial);
        setUploading(true);
        cancelReviewEditor();
        // Check
        axios
            .post(TestimonialsUpdateOrCreate,testimonial)//,auth())
            .then(response => {
                console.log()
                const updatedTestimonial = response['data'] as ITestimonial;
                const tmpArr = reviews;
                const index = reviews.findIndex(review => review.id === testimonial.id);
                if (index > -1){
                    tmpArr[index] = updatedTestimonial;
                }
                else {
                    tmpArr.push(updatedTestimonial);
                }
                setReviews(tmpArr);
            })
            .catch(error => {
                console.log("Error");
                console.log(error);
            })
            .finally(() => {
                setUploading(false)
            })

    }

    const updateReview = (index: number) => {
        console.log("Selected review");
        console.log(reviews[index]);
        setSelectedReview(reviews[index])
        setEditorOpen(true)
    }

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }
        // const uri = ImagesArrayReorder;
        const reorderedReviews:ITestimonial[] = reorder(
            reviews,
            result.source.index,
            result.destination.index
        );

        // //Set new position on the order for the element
        reorderedReviews.forEach((item: ITestimonial, index:number) => {
            item.order=index
        });
        setReviews(reorderedReviews)
        // //Create the element
        const returnArray:IReorder[] = reorderedReviews.map((item: ITestimonial) => {
            const returnImg:IReorder = {
                id: item.id,
                order: item.order
            }
            return returnImg;
        })
        axios
                                                       .post(TestimonialsReorder, returnArray)//, auth())
                                                       .then(data => {
                                                           postUpdate('Placering er opdateret', 'success')
                                                       })
                                                       .catch(err => {
                                                           postUpdate('Placering blev ikke gemt', 'error')
                                                           console.log(err)
                                                       })

    }

    const deleteTestimonial = (testimonial:ITestimonial, index: number) => {
        const uri = TestimonialDelete + testimonial.id;
        axios
            .post(uri, {})//, auth())
            .then(data => {
                postUpdate("Udtalelse blev slettet", 'success');
                const newState = reviews.filter(review => review.id !== testimonial.id);
                newState.forEach((item, index) => item.order = index);
                setReviews(newState);
            })
            .catch(err => {
                console.log(err);
                postUpdate("Udtalelse blev ikke slettet.", 'error')
            })
    }

    const postUpdate = (message: string,variant: VariantType) => {
        enqueueSnackbar(message, { variant });
    }

    return(
        <Wrapper>
            <HeadlineWrapper>
                <Headline
                    title="Udtalelser" />
            </HeadlineWrapper>
            <DragDropContextWrapper>
                <StyledDragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided: DroppableProvided, snapshot: any) => (
                            <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                                {...provided.droppableProps}
                            >
                                {reviews.map((review, index) => (
                                    <Draggable key={index.toString()} draggableId={index.toString()} index={index}>
                                        {(provided: any, snapshot:any) => (
                                            <StyledGridListTile
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <Actions>
                                                    <EditButton
                                                        onClick={() => updateReview(index)}
                                                    >
                                                        <EditIcon />
                                                    </EditButton>

                                                    <DeleteButton
                                                        onClick={() => deleteTestimonial(review, index)}>
                                                        <DeleteForeverIcon />
                                                    </DeleteButton>
                                                </Actions>
                                                <TestimonialCard
                                                    key={index}
                                                    data={review} />
                                            </StyledGridListTile>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                                {uploading? (
                                    <LoadingWrapper>
                                        <Loading message="Uploader review..." />
                                    </LoadingWrapper>
                                ) : (
                                    <></>
                                )}
                                <StyledAddButton
                                openEditor={openReviewEditor}
                                title="Tilføj en ny udtalelse"/>
                        </div>
                    )}
                    </Droppable>
                </StyledDragDropContext>
            </DragDropContextWrapper>
            <ReviewEditor
                onClose={cancelReviewEditor}
                onOkClose={reviewUpdated}
                open={editorOpen}
                testimonial={selectedReview}
            />
        </Wrapper>
    )
}

export default ReviewManager;
