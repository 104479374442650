import React from 'react';
import styled from 'styled-components';
import {
    Backdrop,
    CircularProgress,
    Grid,
    Typography
} from '@material-ui/core';

interface IOverlayLoading {
    open: boolean;
    text: string
}

const SBackdrop = styled(Backdrop)`
    z-index: 10;
    color: #fff;
`;

const SCircularProgress = styled(CircularProgress)`

`;

const SGrid = styled(Grid)`

`;

const OverlayLoading = ({open, text}: IOverlayLoading) => {
    return(
        <SBackdrop open={open}>
            <SGrid container direction="column" alignItems="center">
                <SCircularProgress color="inherit"/>
                <Typography variant="subtitle1" gutterBottom>
                    {text}
                </Typography>
            </SGrid>
        </SBackdrop>
    )
}

export default OverlayLoading;
