import {IPage} from '../../Interfaces/IPage';


const pages: IPage = {

    title: 'Pages',
    id: 'pages',
    pages: [
        {
            title: 'Produkter',
            href: '',
            content_type: 'link'
        },
        {
            title: 'Kurv',
            href: '',
            content_type: 'link'
        },
        {
            content_type: 'button',
            href: '/logout',
            title: 'Log ud'
        }
    ],
};

export default pages;
