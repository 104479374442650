import React, { useEffect } from 'react';
import {
    Grid,
    Button,
} from '@material-ui/core';
import styled from 'styled-components';
import { INewImage } from '../../../Interfaces/IImages';
import ImageTextEditor from './ImageTextEditor';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import ImageUploading, { ImageListType, ImageType } from "react-images-uploading";
import ImageCrop from './ImageCrop';
import EditIcon from '@material-ui/icons/Edit';
import TextFieldsIcon from '@material-ui/icons/TextFields';

interface IImagesSelector {
    images: INewImage[];
    onNewImages: (newImages:Array<Array<INewImage>>) => void;
}

const ImageSelectorGrid = styled(Grid)`
    max-width: 450px;
    max-height: 450px;
    width: 100vw;
    height: 100vw};
    margin: 0 auto;
`;

const ImageGridItemWrapper = styled(Grid)`
    flex-grow: 1;
    height: 100%;
    width: 50%;
    max-width: 100%;
`;

const ImageGridItemContainer = styled(Grid)`
    height: 100%;
`;

const ImageGridItem = styled(Grid)`
    position: relative;
    width: 100%;
    flex-grow: 1;
    border: 1px solid black;
    margin-top: 7px;
    margin-bottom: 7px;
`;

const SelectImageButton = styled(Button)`
    padding: 0px;
    width: 100%;
    height: 100%;
`;

const ImageWrapper = styled.div`
    display: flex;
    position: relative;
`;
const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const TextWrapper = styled.div`
    position: absolute;
    bottom: 0;
    color: white;
    z-index: 9999;
    height: 90px;
    width: 100%;
    text-align: left;
    background-color: #0006;
`;
const TextContent = styled.div`
    padding-left:10px;
    padding-right: 10px;
`;

const AddTextButton = styled(Button)`
    position: absolute;
    right: -20px;
    top: -25px;
    z-index: 9999;
    position: absolute;
    width: 70px !important;
    height: 40px !important;
    background-color: white;
    padding: 0;
    border-radius: 10px;
    border: 3px solid black;
    min-width: 40px !important;
    &:hover {
        background-color: #c3c3c3;
    }
`;

const AddImageAction = ({image, text, index}: INewImage) => {
    return (
        <>
            {image.dataURL === ""? (
                <ImageGridItemContainer container
                                        direction="column"
                                        justify="center"
                                        alignItems="center">
                    <PhotoCamera />
                    <b>TILFØJ BILLEDE</b>
                </ImageGridItemContainer>
            ) : (

                <ImageWrapper>
                    <Image src={image.dataURL} alt={text} />
                    {typeof text !== "undefined" && text.length > 0? (
                        <TextWrapper>
                            <TextContent>
                                {text}
                            </TextContent>
                        </TextWrapper>
                    ) : (<></>)}
                </ImageWrapper>
            )}

        </>
           )
}

const GetImagesArray = (images: Array<INewImage>) => {
    var modifiedImagesArr:Array<Array<INewImage>>= [];
    switch(images.length){
        case 1:
            images[0].dimensions = [1,1];
            modifiedImagesArr.push(images);
            break;
        case 2:

            modifiedImagesArr = images.map(img => {
                img.dimensions = [1,2]
                return [img]
            });
            break;
        case 3:
            var imgZero = images[0]
            imgZero.dimensions = [1,2];
            modifiedImagesArr.push([imgZero]);

            modifiedImagesArr.push(images.slice(1).map(img => {
                img.dimensions=[1,1];
                return img;
            }));
            break;
        default:
            for(var i = 0; i < images.length; i+=2){
                modifiedImagesArr.push([images[i], images[i+1]]);
            }
            break;
    }
    return modifiedImagesArr;
}


interface ITextEditorState {
    open: boolean;
    image: INewImage,
    index: [number, number],
}

const ImagesSelector = ({images, onNewImages}:IImagesSelector) => {
    console.log("images");
    console.log(images)
    const textEditorDefaultState:ITextEditorState = {open: false, image: {} as INewImage, index: [-1,-1]};
    const [selectedIndex, setSelectedIndex] = React.useState<[number, number]>([-1,-1]);
    const [selectedImages, setSelectedImages] = React.useState([]);
    const [selectedImage, setSelectedImage] = React.useState<INewImage>();
    const [imageCropOpen, setIsImageCropOpen] = React.useState(false);
    const [textEditorState, setTextEditorState] = React.useState<ITextEditorState>(textEditorDefaultState);
    const [modifiedImagesArr, setModifiedImagesArr] = React.useState<Array<Array<INewImage>>>(GetImagesArray(images));
    const maxNumber = images.length;
    const onChange = (
        imageList: ImageListType,
        addUpdateIndex: number[] | undefined
    ) => {
        if (selectedIndex[0] > -1 && selectedIndex[1] > -1){
            var itemToUpdate = modifiedImagesArr[selectedIndex[0]][selectedIndex[1]];
            itemToUpdate.image = imageList[0];
            setSelectedImage(itemToUpdate);
            setIsImageCropOpen(true);
        }
        else {
            console.log("Something went wrong. ");
        }
    };


    const uploadImage = (onImageUpload: () => void, index: [number, number]) => {
        setSelectedIndex(index);
        onImageUpload()
    }

    const setTextForImage = (image: INewImage, index: [number, number]) => {
        setTextEditorState({
            open: true,
            image: image,
            index: index
        });
    }
    const cancelTextEditor = () => {
        setTextEditorState(textEditorDefaultState);
    }
    const updateText = (text: string) => {
        const index = textEditorState.index;
        const itemToUpdate = modifiedImagesArr[index[0]][index[1]]
        itemToUpdate.text = text;
        modifiedImagesArr[index[0]][index[1]] = itemToUpdate
        setModifiedImagesArr(modifiedImagesArr);
        onNewImages(modifiedImagesArr);
        cancelTextEditor();
    };

    const setCroppedImage = (img: INewImage) => {
        //Fetch the image from the array
        var itemToUpdate = modifiedImagesArr[selectedIndex[0]][selectedIndex[1]];
        //Set the new image to the cropped image
        itemToUpdate = img;
        //Update the array with the new images
        modifiedImagesArr[selectedIndex[0]][selectedIndex[1]] = itemToUpdate;
        //Update state
        setModifiedImagesArr(modifiedImagesArr);
        onNewImages(modifiedImagesArr);
        // Just for security. Make sure that we don't set another picture in another state.
        setSelectedIndex([-1, -1]);
        setIsImageCropOpen(false);
    }

    return (
        <>
            <ImageUploading
                multiple
                value={selectedImages}
                onChange={onChange}
                maxNumber={maxNumber}
            >
                {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps
                }) =>(
                    <ImageSelectorGrid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        {modifiedImagesArr.map((imageArr, index) => (
                            <ImageGridItemWrapper item>
                                <ImageGridItemContainer container
                                                        direction="row"
                                                        justify="center"
                                                        alignItems="stretch"
                                >
                                    {imageArr.map((image, imgIndex) => (
                                        <ImageGridItem item>
                                            <AddTextButton onClick={() => setTextForImage(image, [index, imgIndex])}>
                                                <EditIcon /><TextFieldsIcon />
                                            </AddTextButton>
                                            <SelectImageButton
                                                onClick={() => uploadImage(onImageUpload, [index, imgIndex])}>
                                                <AddImageAction image={image.image} text={image.text} index={image.index} />
                                            </SelectImageButton>
                                        </ImageGridItem>
                                    ))}
                                </ImageGridItemContainer>
                            </ImageGridItemWrapper>

                        ))}
                    </ImageSelectorGrid>
                )}
            </ImageUploading>
            {
                imageCropOpen? (
                    <ImageCrop open={imageCropOpen} selectedImage={selectedImage? selectedImage : {} as INewImage} onCropped={setCroppedImage}/>
                ): (<></>)
            }
            <ImageTextEditor
                image={textEditorState.image}
                open={textEditorState.open}
                onReturn={updateText}
                onHandleCancel={cancelTextEditor}
            />
        </>
    )
}

export default ImagesSelector;
