import React from 'react';
import {
    Grid,
    Button,
} from '@material-ui/core';
import {ReactComponent as one} from '../../../assets/Blocks/SingleBlock.svg';
import {ReactComponent as two} from '../../../assets/Blocks/TwoBlocks.svg';
import {ReactComponent as three} from '../../../assets/Blocks/ThreeBlocks.svg';
import {ReactComponent as four} from '../../../assets/Blocks/FourBlocks.svg';
import styled from 'styled-components';

interface IImageAmountSelection {
    onImageCountSelection: (index: number) => void;
}

const BlackGridBackground = styled(Grid)`
    width: 100%;
    height: 100%;
    background-color: black;
`;

const ImageAmountButton = styled(Button)`
    padding: 10px;
    display: block;
`;

const BlockInfo = styled(Grid)`
   width: 85px;
    text-align: center;
`;

const BlockInfoText = styled.p`
    margin-top: 5px;
`


const BlockImages = [
    one,
    two,
    three,
    four
];

const ImageAmountSelection = ({onImageCountSelection}: IImageAmountSelection) => {
    const tmp = (index: number) => {
        onImageCountSelection(index);
    }
    return(
        <Grid container
              direction="row"
              justify="space-around"
              alignItems="flex-start"
        >
            {
                BlockImages.map((Block, index) => (
                    <BlockInfo container
                               direction="column"
                               justify="center"
                               alignItems="center"
                            key={index}
                    >
                        <ImageAmountButton
                            variant="outlined"
                            onClick={() => tmp(index)}>
                                <Block fontSize="small" style={{display: 'block', width: '50px', height: '50px'}}/>
                        </ImageAmountButton>
                        <BlockInfoText>
                            Tilføj {index+1} {index === 0? "nyt billed" : "nye billeder"}
                        </BlockInfoText>
                    </BlockInfo>
                ))
            }
        </Grid>
    )
}

export default ImageAmountSelection
