import React from 'react';
import styled from 'styled-components';
import {ITextImagesArray, ITextImage} from '../../../Interfaces/IImages';
import {
    Grid,
    Button
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';

const SquareGrid = styled(Grid)`
    max-width: 100%;
    max-height: 100%;
    height: ${props => props.theme.palette.sectionSeparator.height}px;
    width: ${props => props.theme.palette.sectionSeparator.height}px;
    padding: 8px;
    margin: 0 auto;
    flex-wrap: nowrap;
`;

const SquareGridNoPadding = styled(SquareGrid)`
    padding: 0px;
`;

const SquareImageWrapper = styled.div`
    position: relative;
    background-color: ${props => props.theme.palette.sectionSeparator.color.main};
    width: 100%;
    height: 100%;
`;

const StyledGridItem = styled(Grid)`
    max-width: 100%;
    height: 100%;
    padding-left: 6px;
`;
const StyledImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
const StyledSquareImageGrid = styled(Grid)`
    width: 100%;
    height: 50%;
`;
const StyledSquareImageGridBottomPadding = styled(StyledSquareImageGrid)`
    padding-bottom: 5px;
`;
const StyledSquareImageGridTopPadding = styled(StyledSquareImageGrid)`
    padding-top: 5px;
`;
const StyledSquareImageGridFullHeight = styled(StyledSquareImageGrid)`
    height: 100%;
    padding: 0px;
 `;
const FourSquareGrid = styled(SquareGrid)`
    padding: 0px;
     flex-wrap: wrap;
     @media(max-width: 600px){
         flex-wrap: no-wrap;
    }
`;

const ImageCollectionActions = styled.div`
    z-index: 999;
`;

const DeleteButton = styled(Button)`
    position: absolute;
    right: 5px;
    top: 1px;
    width: 40px !important;
    height: 40px !important;
    background-color: white;
    padding: 0;
    border-radius: 50%;
    border: 3px solid black;
    min-width: 40px !important;
    &:hover {
        background-color: #c3c3c3;
    }
`;

const ImageTextWrapper = styled.div`
    color: white;
    z-index: 1;
    height: 90px;
    width: 100%;
    text-align: left;
    background-color: #0006;
    position: absolute;
    bottom: 0;
`;

const ImageTextContent = styled.div`
    padding:10px;
`;

const EditButton = styled(DeleteButton)`
    right: 50px;
`;

interface ISquareImage {
    text_image: ITextImage;
}

const SquareImage = ({text_image}: ISquareImage) => {
    return (
        <SquareImageWrapper>
            <StyledImg src={text_image.image} />
            {text_image.text.length > 0? (
                <ImageTextWrapper>
                    <ImageTextContent>
                        {text_image.text}
                    </ImageTextContent>
                </ImageTextWrapper>
            ) : (<></>)}
        </SquareImageWrapper>
    )
}

interface IImage {
    images: ITextImagesArray;
    onImageRemove?: (id:string) => void;
    onImageCollectionUpdate?: (id: string) => void;
}



const PhotoGrid = ({images, onImageRemove, onImageCollectionUpdate}: IImage) => {
    const isAdminView = window.location.href.includes('dashboard');
    const onDelete = () => {
        if(typeof onImageRemove !== "undefined"){
            onImageRemove(images.id? images.id : "");
        }
    }

    const onUpdateImages = () => {
        if(typeof onImageCollectionUpdate !== "undefined"){
             onImageCollectionUpdate(images.id? images.id : "");
        }
    }

    function mapImages(imgArr:ITextImagesArray){
        if(imgArr.text_images.length === 2){
            const returnVal = (
                <SquareGrid container>
                    {imgArr.text_images.map(img => {
                        return(
                        <SquareGrid item xs={6}>
                            <SquareImage text_image={img as ITextImage} />
                        </SquareGrid>
                    )})}
                </SquareGrid>
            )
            return returnVal;
        }
        else if (imgArr.text_images.length === 3){
            const returnValue = (
                <SquareGrid container>
                    <StyledSquareImageGridFullHeight item xs={6}>
                        <SquareImage text_image={imgArr.text_images[0]} />
                    </StyledSquareImageGridFullHeight>
                    <StyledGridItem item xs={6}>
                        <StyledGridItem container direction="row">
                                <StyledSquareImageGridBottomPadding item >
                                    <SquareImage text_image={imgArr.text_images[1]} />
                                </StyledSquareImageGridBottomPadding>
                                <StyledSquareImageGridTopPadding item >
                                    <SquareImage text_image={imgArr.text_images[2]} />
                                </StyledSquareImageGridTopPadding>
                        </StyledGridItem>
                    </StyledGridItem>
                </SquareGrid>
            )
            return returnValue;
        }
        else if(imgArr.text_images.length === 4){
            const returnValue = (
                <FourSquareGrid container
                    spacing={2}>
                    {imgArr.text_images.map(image => (
                        <StyledSquareImageGrid item xs={6}>
                            <SquareImage text_image={image} />
                        </StyledSquareImageGrid>
                    ))}
                </FourSquareGrid>
            )
            return returnValue;
        }
        else {
            const returnValue = imgArr.text_images.map(image => (
                <SquareGrid item>
                    <SquareImage text_image={image} />
                </SquareGrid>
            ))
            return returnValue;
        }
    }
    return (
        <SquareGrid container
              direction="column"
        >
            {isAdminView? (
                <ImageCollectionActions>
                    <EditButton
                        onClick={onUpdateImages}
                    >
                        <EditIcon />
                    </EditButton>

                    <DeleteButton
                        onClick={onDelete}>
                        <DeleteForeverIcon />
                    </DeleteButton>
                </ImageCollectionActions>
            ) : (
                <></>
            )}
            {mapImages(images)}
        </SquareGrid>
    )
}

export default PhotoGrid;
