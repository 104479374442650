import React from 'react';
import styled from 'styled-components';
import {
    Card,
    Grid,
    Paper
} from '@material-ui/core';
import TestimonialHeader from './TestimonialHeader';
import TestimonialBody from './TestimonialBody';

const PaperWidth = 400;

interface TestimonialProps {
    company_name: string;
    company_image: string;
    reviewer_name: string;
    reviewer_image: string;
    text: string;
}

interface ITestimonialCard{
    data: TestimonialProps;
}

const DivRoot = styled.div`
    display: block;
    height: 100%;
    width: 100%;
`;

const TestimonialGridItem = styled(Grid)`
    @media(max-width: 400px){
        width: 100%
    }
`;

const StyledPaper = styled(Paper)`
    width: ${PaperWidth}px;
    margin: 0 auto;
    margin-top: 10px;
    height: 450px;
    display: flex;
    @media(max-width: 400px){
        width: 100%;
    }
`;
const StyledCard = styled(Card)`
    width: 100%;
    height: 100%;
`;


const TestimonialCard = ({data}: ITestimonialCard) => {
    return (
        <TestimonialGridItem item>
            <StyledPaper>
                <StyledCard>
                    <DivRoot>
                        <Grid container
                              direction="column"
                              justify="space-around"
                              alignItems="stretch"
                        >
                            <TestimonialHeader
                                image={data.reviewer_image}
                                name={data.reviewer_name}
                            />
                            <TestimonialBody
                                image={data.company_image}
                                text={data.text}
                                company_name={data.company_name}
                            />
                        </Grid>
                    </DivRoot>
                </StyledCard>
            </StyledPaper>
        </TestimonialGridItem>
    )
}

export default TestimonialCard;
