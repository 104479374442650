import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';

interface IConfirmationDialog {
    open: boolean;
    headline: string;
    body: string;
    okAction: () => void;
    cancelAction?: () => void;
    cancelString?: string;
    okString?: string;
}

const ConfirmationDialog = ({open, headline, body, okAction, cancelAction, cancelString, okString}:IConfirmationDialog) => {

    return(
        <Dialog
            open={open}
            onClose={cancelAction}
        >
            <DialogTitle>{headline}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {body}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {cancelAction? (
                    <Button onClick={cancelAction} color="primary">
                        {cancelString? (<>{cancelString}</>) : (<>Cancel</>)}
                    </Button>
                ): (<></>)}
                <Button onClick={okAction} color="primary" autoFocus>
                    {okString? (<>{okString}</>) : (<>Ok</>)}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmationDialog;
