import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { checkLoggedIn, getUser } from '../Dashboard/Utils/Authentication';
import store from 'store';
import { IUser, IB2BCompany } from '../../Interfaces/IUser';
import Header from '../Header';
import Sidebar from '../Sidebar';
import pages from '../navigation/WebshopPages';
import { useMediaQuery, Divider, useTheme } from '@material-ui/core';
import ProductManager from './Product/ProductManager';
import {
    Switch,
    Route,
    Link,
    useRouteMatch,
    useHistory,
    useParams
} from "react-router-dom";
import OrderView from './Product/OrderView';
import CheckoutView from './Checkout/CheckoutView';
import Image from '../Image';
import axios from 'axios';
import { GetCompanyDetails, PageRoot } from '../../static/api_uris';
import CheckoutFinished from './Checkout/CheckoutFinished';

interface IParameters{
    id: string;
}

const Wrapper = styled.div`

`;

const LogoContainer = styled.div`
    width: 190px;
    height: 100%;
    @media(max-width: 600px){
        width: 85px;
        height: 100%;
    }
`;
const StyledHeader = styled(Header)`
    background-color: #fff !important;
`;

const StyledImage = styled(Image)`
    width: 100%;
    height: 100%;
`;

const LogoWrapper = styled.div`
`;

const WebshopManager = () => {
    let history = useHistory()
    const parameters = useParams() as IParameters;
    const [openSidebar, setOpenSidebar] = React.useState(false);

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const open = isMd ? false : openSidebar;

    //const params = useParams()
    let { path, url } = useRouteMatch();
    pages.pages[0].href = url;
    console.log("URL")
    pages.pages[1].href = url + "/checkout"
    //const [loggedIn, setLoggedIn] = useState(checkLoggedIn());
    const [company, setCompany] = useState<IB2BCompany>({} as IB2BCompany);

    const handleSidebarOpen = () => {
        setOpenSidebar(true);
    };
    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };
    useEffect(() => {
        if (checkLoggedIn()){
            const user = store.get('user') as IUser;
            console.log(user.company_id);
            var company_id = "";
            console.log(!user.company_id)

            company_id = user.company_id? user.company_id : parameters.id
            console.log("Company ID")
            console.log(company_id)
            fetchStoreDetails(company_id);
        }
        else {
            history.push(PageRoot)
        }

    },[])

    const fetchStoreDetails = (id:string) => {
        axios.get(GetCompanyDetails(id))
             .then(response => {
                 console.log("fetchStoreDetails")
                 console.log(response["data"])
                 const data = response["data"]
                 setCompany(data);
             })
             .catch(error => {
                 console.log("Error fetching company");
                 console.log(error);
             })
    }
    const Logo = (
        <LogoContainer>
            <LogoWrapper>
                <Link to={url}>
                    <StyledImage
                        src={company.company_image}
                        alt={company.company_name}
                        lazy={false}
                    />
                </Link>
            </LogoWrapper>
        </LogoContainer>
    )

    return(
        <Wrapper>
            <StyledHeader
                LogoIcon={Logo}
                pages={pages}
                onSidebarOpen={handleSidebarOpen}/>
            <Sidebar
                onClose={handleSidebarClose}
                open={open}
                variant="temporary"
                pages={pages}
            />
            <Divider />
            <Switch>
                <Route exact path={path}>
                    <ProductManager webshopId={company.id} />
                </Route>
                <Route path={`${path}/order/:productId`}>
                    <OrderView />
                </Route>
                <Route exact path={`${path}/checkout`}>
                    <CheckoutView />
                </Route>
                <Route path={`${path}/checkout/:publicId/finished`}>
                    <CheckoutFinished />
                </Route>
            </Switch>
        </Wrapper>
    )

}

export default WebshopManager;
