import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';
import {
    useMediaQuery,
    Divider
} from '@material-ui/core';

import Header from '../Header';
import Sidebar from '../Sidebar';
import pages from '../navigation/DashboardPages';
import SectionSeparator from '../SectionSeparator';
import PhotoSectionEditor from './PhotoSectionEditor';
import BrandManager from './Brands/BrandManager';
import ReviewManager from './Reviews/ReviewManager';
import { getUser } from './Utils/Authentication';
import Image from '../Image';
import { Link, useRouteMatch } from "react-router-dom";
import { PageRoot } from '../../static/api_uris';
import ListCompanies from './WebshopManagement/ListCompanies';
import BundledOrders from './WebshopManagement/OrdersView';


interface IDashboard {
    LightLogo: string;
}

const DashboardWrapper = styled.div`
    max-width 100%;
    margin: 0 auto;
    background-color: #fff0;
`;

const StyledImage = styled(Image)`
    width: 100%;
    height: 100%;
`;
const LogoContainer = styled.div`
    width: 110px;
    height: 100%;
    @media(max-width: 600px){
        width: 85px;
        height: 100%;
    }
`;

const Dashboard = ({ LightLogo }: IDashboard) => {
    let history = useHistory();
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const [openSidebar, setOpenSidebar] = React.useState(false);

    useEffect(() => {
        const user = getUser();
        if (typeof user === "undefined" || !user.is_staff) {
            history.push(PageRoot)
        }
    })

    const handleSidebarOpen = () => {
        setOpenSidebar(true);
    };
    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };
    const palette = theme.palette as any;
    const sectionSeparator = palette.sectionSeparator;

    const open = isMd ? false : openSidebar;

    const Logo = (
        <LogoContainer>
            <Link to={useRouteMatch()['url']}>
                <StyledImage
                    src={LightLogo}
                    alt="dashboard"
                    lazy={false} />
            </Link>
        </LogoContainer>
    )

    return (
        <DashboardWrapper>
            <Header
                LogoIcon={Logo}
                pages={pages}
                onSidebarOpen={handleSidebarOpen} />
            <Sidebar
                onClose={handleSidebarClose}
                open={open}
                variant="temporary"
                pages={pages}
            />
            <Divider />
            <ListCompanies />
            <br />
            <SectionSeparator height={sectionSeparator.heightMd}>
                <PhotoSectionEditor />
            </SectionSeparator>
            <br />
            <BrandManager />
            <br />
            <ReviewManager />
            <BundledOrders />
        </DashboardWrapper>
    )
}

export default Dashboard;
