//Photos / Images api
export const ImagesArray = "/images/array/";
export const ImagesCollectionDelete = "/images/delete/collection/";
export const ImagesCollectionAdd = "/images/add/collection/"
export const ImagesCollectionUpdate = "/images/update/collection/"
export const ImagesArrayReorder = "/images/reorder/array/"


// Brand api
export const CategoriesList = "/categories/list/";
export const UpdateBrand = "brand/update/";

//Testimonial api
export const TestimonialsList = "/customers/testimonials";
export const TestimonialsUpdateOrCreate = "/customers/testimonial/update_or_create";
export const TestimonialsReorder = "/customers/testimonials/reorder";
export const TestimonialDelete = "/customers/testimonial/delete/";


//Account Management
const root = "/accounts"
export const AccountLogin = root+'/login';
export const AccountRefreshToken = root + '/refresh_token';
export const AccountProfile = root + '/profile';


// Webshop
export const webshop_root = "/webshop/";
export const GetProductList = (webshop_id:string) => { return webshop_root+webshop_id+"/product_list/" }
export const GetProductDetails = (webshop_id:string, product_id:string) => { return webshop_root+webshop_id+"/product/"+product_id+"/" }
export const GetCompanyDetails = (webshop_id:string) => webshop_root+webshop_id+"/";
export const PostOrderBundle = (webshop_id:string) => webshop_root + webshop_id + "/new_order/"
export const GetOrderBundle = (public_id:string) => webshop_root + "get_public_id_order/" + public_id + "/"

//Dashboard
export const GetAllCompanies = webshop_root+"all_companies/";
export const GetAllOrders = webshop_root+"all_orders/";

// Page navigation
export const PageRoot = "/";
export const OrderDetails = (company_id:string, public_id:string) => `${webshop_root}${company_id}/checkout/${public_id}/finished`
