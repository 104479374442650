import React from 'react';
import styled from 'styled-components';
import {
    Tooltip,
    IconButton,

} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ITextImagesArray, INewImage, } from '../../../Interfaces/IImages';
import ManagePhotoCollection from './ManagePhotoCollection';

interface IAddImages {
    onAddImages: () => void;//(newImages: ITextImagesArray) => void;
}

const StyledIconButton = styled(IconButton)`
    width: 150px;
    height: 150px;
    background: white;
    border-radius: 10px;
    color: black;
    &:hover {
         background-color: rgb(232, 232, 232);
    }
`;

const StyledAddIcon = styled(AddIcon)`
    font-size: 70px;
`;


interface IState {
    images: INewImage[]
}

const AddImages = ({onAddImages}: IAddImages) => {
    const [state, setState] = React.useState<IState>({
        images: []
    })

    const [openDialog, setOpenDialog] = React.useState(false);
    //const [newImages, setNewImages] = React.useState<ITextImagesArray>({} as ITextImagesArray)

    const addImages = () => {
        onAddImages()
        // console.log("Add Images");
        // setState({images:[]})
        // setOpenDialog(true);
    }

    const handleClose = () => {
        setState({
            images: []
        })
        setOpenDialog(false);
    };

    const handleOkClose = (newImages: ITextImagesArray) => {
        console.log("Send images to parent component");
       // onAddImages(newImages)
        handleClose();
    };




    return(
        <>
            <Tooltip title="Tilføj nye billeder" aria-label="add">
                <StyledIconButton
                    aria-label="upload picture"
                    onClick={onAddImages}>
                    <StyledAddIcon />
                </StyledIconButton>
            </Tooltip>


        </>
    )
}

export default AddImages;
