import { ErrorMessage } from "@hookform/error-message";
import { FieldErrors } from "react-hook-form";
import styled from 'styled-components';

//Form error handling TODO: move to new file, all form things.
type ErrorSummaryProps<T> = {
  errors: FieldErrors<T>;
};

function ErrorSummary<T>({ errors }: ErrorSummaryProps<T>) {
  if (Object.keys(errors).length === 0) {
    return null;
  }
  return (
    <div className="error-summary">
        {Object.keys(errors).map((fieldName) => (
            <ErrorMessage errors={errors} name={fieldName as any} as="div" key={fieldName} />
      ))}
    </div>
  );
}
type ErrorMessageContainerProps = {
  children?: React.ReactNode;
};

interface ErrorMessageFieldProps{
    errors?: any;
    name: string;
}

const StyledSpan = styled.span`
    position: absolute;
    bottom: -10px;
    width: 100%;
    color: red;
    left: 12px;
`;

const ErrorMessageContainer = ({ children }: ErrorMessageContainerProps) => (
  <StyledSpan className="error">{children}</StyledSpan>
);

const ErrorMessageField = ({errors, name}: ErrorMessageFieldProps) => (
    <ErrorMessage
        errors={errors}
        name={name}
        as={<ErrorMessageContainer />}
    />
)

export default ErrorMessageField;
