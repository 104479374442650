import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
    Button,
    Grid,
    Paper,
    TextField
} from '@material-ui/core';
import ErrorMessageField from '../ErrorMessage';
import axios from 'axios';
import { useForm } from "react-hook-form";
import { VariantType, useSnackbar } from 'notistack';
import { AccountLogin } from '../../static/api_uris';
import {IUser} from '../../Interfaces/IUser';
import { setUser, checkLoggedIn, getUser, logoutAction } from '../Dashboard/Utils/Authentication';
import {useHistory} from 'react-router-dom';
//import Cookies from 'js-cookie';

const Wrapper = styled.div`
    margin: 0 auto;
    max-width: 400px;
    min-height: 400px;
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
`;

const StyledPaper = styled(Paper)`
    height: 100%;
    weight: 100%;
    padding: 10px;
`;

const LoginButton = styled(Button)`
    font-size: 1.2em;
    color: ${props => props.theme.palette.primary.contrastText};
    background-color: ${props => props.theme.palette.primary.dark};
    border-radius: 10px;
`;

const StyledForm = styled.form`

`;

const GridWrapper = styled(Grid)`
`;

const StyledGrid = styled(Grid)`
    position: relative;
    width: 80%;
`;


interface ILogin {
    //onLoginSuccess: () => void;
}

interface ILoginData {
    username: string;
    password: string;
}

const Login = ({}: ILogin) => {
    let history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [loginData, setLoginData] = React.useState<ILoginData>({
        username: "",
        password: ""
    });
    const { register, handleSubmit, errors } = useForm<FormData>();

    useEffect(() => {
        if(checkLoggedIn()){
           redirectUser();
        }
    })

    const redirectUser = () => {
        const user = getUser();
        if (user.is_staff){
            console.log("is_staff")
            history.push("/dashboard")
        }
        else if (typeof user.company_id !== "undefined"){
            console.log("no company")
            history.push("/webshop/"+user.company_id);
        }
        else {
            console.log("restart")
            logoutAction();
            history.go(0);
        }
    }

    const postUpdate = (message: string,variant: VariantType) => {
        enqueueSnackbar(message, { variant });
    }
   const onSubmit = handleSubmit((props: ILoginData)  => {
        if (props){
            if(Object.entries(errors).length === 0){
                axios
                    .post(AccountLogin, props)
                    .then(data => {
                        console.log("successfull login")
                        console.log(data)
                        console.log(data.headers);

                        const access_token = data["data"]["access_token"];
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
                        // const csrftoken = Cookies.get('csrftoken'); // or the value from settings.CSRF_COOKIE_NAME
                        // if (csrftoken) {
                        //     axios.defaults.headers.common['X-CSRFToken'] = csrftoken;
                        // }
                        postUpdate("Logget ind", "success");
                        const user = data["data"]["user"] as IUser;
                        setUser(user)
                        //onLoginSuccess()
                    })
                    .catch(err => {
                        console.log("Error");
                        console.log(err);
                        postUpdate("Problemer med at logge ind", "error");
                    })
                    .finally(() => redirectUser())

            }
        }
    });

    return (
        <Wrapper>
            <StyledPaper elevation={2}>
                <StyledForm onSubmit={onSubmit}>
                    <GridWrapper container
                                direction="column"
                                justify="center"
                                alignItems="center"
                                spacing={4}
                    >
                        <h2>Login:</h2>
                        <StyledGrid item xs={12}>
                            <TextField
                                id="username"
                                defaultValue={loginData.username}
                                name="username"
                                inputRef={register({required: "email er påkrævet"})}
                                label="Email"
                                variant="outlined"
                                fullWidth
                            />
                            <ErrorMessageField
                                errors={errors}
                                name="username"/>
                            <br />
                        </StyledGrid>
                        <StyledGrid item>
                            <TextField
                                id="password"
                                defaultValue={loginData.password}
                                name="password"
                                inputRef={register({required: "adgangskode er påkrævet"})}
                                label="Password"
                                type="password"
                                autoComplete="current-password"
                                variant="outlined"
                                fullWidth
                            />
                            <ErrorMessageField
                                errors={errors}
                                name="password"/>
                            <br />
                        </StyledGrid>
                        <Grid item>
                            <LoginButton variant="outlined" type="submit">
                                LOGIN
                            </LoginButton>
                        </Grid>
                    </GridWrapper>
                </StyledForm>
            </StyledPaper>
        </Wrapper>
    )
}

export default Login;
