import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import {
    Card,
    CardContent,
    CardHeader,
    Typography
} from '@material-ui/core'
import { useParams } from 'react-router-dom';
import { GetCompanyDetails, GetOrderBundle } from '../../../static/api_uris';
import axios from 'axios';
import CheckoutDetails from './CheckoutDetails';
import { IProduct, ISize, IColor } from '../../../Interfaces/IProduct';
import { IB2BCompany } from '../../../Interfaces/IUser';
import PaymentDetailsCard from './components/PaymentDetailsCard';
import MP from '../../../assets/MP.png'

interface IParameters {
    id: string;
    publicId: string;
}
interface IOrder {
    gender: string;
    product: IProduct;
    size: ISize;
    image: { image: string; }
    color: IColor;
    price: number;
}

interface IBundledOrder {
    pk: number;
    public_id: string;
    orders: IOrder[]
}

interface IBundledOrder {
    public_id: string;
    orders: IOrder[];
    message: string;
}
type KeyValuePairs = {
  [key: string]: string;
};
const OrderCard = styled(Card)`
    border: 1px solid black;
    position: relative
`;

const ImageWrapper = styled.div`
    width: 70px;
    height: 70px;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
`;

const Wrapper = styled.div`
    max-width:500px;
    margin: 0 auto;
    margin-top: 10px;
`



const CheckoutFinished = () => {
    const parameters = useParams() as IParameters;
    const public_id = parameters.publicId.split("-")[0]
    const [bundledOrder, setBundledOrder] = useState<IBundledOrder>()
    const [message, setMessage] = useState<KeyValuePairs[]>([]);
    const [company, setCompany] = useState<IB2BCompany>({} as IB2BCompany)

    useEffect(() => {
        axios.get(GetOrderBundle(parameters.publicId))
            .then(response => {
                console.log("Data:")
                console.log(response["data"])
                setBundledOrder(response["data"])

            })
            .catch(err => {
                console.log("error orders")
                console.log(err);
            })
        axios.get(GetCompanyDetails(parameters.id))
            .then(response => {
                console.log("fetchStoreDetails")
                console.log(response["data"])
                const data = response["data"]
                setCompany(data);
            })
            .catch(error => {
                console.log("Error fetching company");
                console.log(error);
            })

    }, [])

    const processMessage = (inputString: string): KeyValuePairs[] => {
        const lines = inputString.trim().split('\n').filter(line => line.trim() !== '' && line.includes(':'));
        const keyValuePairs = lines.map((line) => {
            const [key, ...values] = line.split(':');
            const value = values.join(':').trim(); // Join back in case the value contains colons
            return { [key.trim()]: value };
        });
        return keyValuePairs;
    };
    useEffect(() => {
        if (bundledOrder) {
            const msg = processMessage(bundledOrder?.message);
            setMessage(msg);
        }
    }, [bundledOrder])


    return (
        <Wrapper>
            {(company.mobile_pay && bundledOrder) && (
                <div>
                    <PaymentDetailsCard
                        headline="Husk MobilePay betaling"
                        content={
                            <div>
                                <p>For at kunne gennemføre ordren skal du sende <u>{bundledOrder.orders.reduce((a, b) => a + (Number(b.price) || 0), 0)} DKK</u> via MobilePay.</p>
                                <p>Til nr: <u><b>802997</b></u><br />med beskeden: '<u>ID: {public_id}</u>'</p>
                            </div>
                        }
                        image={MP}
                    />
                </div>
            )}


            {bundledOrder ? (
                <>
                    <h1>Bestilling (ID: {public_id}):</h1>
 <div>
      {message.map((item, index) => (
        <div key={index}>
          {Object.entries(item).map(([key, value]) => (
            <p key={key}>{key}: {value}</p>
          ))}
        </div>
      ))}
    </div>
                    <Grid
                        container
                        direction="column"
                        alignItems="stretch"
                        spacing={2}
                        justify="center"
                        style={{ paddingTop: 20 }}
                    >
                        {bundledOrder.orders.map(order => (
                            <Grid item>
                                <OrderCard elevation={0}>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <ImageWrapper>
                                                <Image src={order.image.image} />
                                            </ImageWrapper>
                                        </Grid>
                                        <Grid item>
                                            <Grid
                                                container
                                                direction="column"
                                                justify="center"
                                                alignItems="stretch"
                                            >
                                                <Grid item>
                                                    <Typography variant="h6" gutterBottom>
                                                        {order.product.name} | {order.product.brand.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1" gutterBottom>
                                                        {order.gender} | {order.product.sizes.filter(a => a.id === order.size.id)[0].name} Pris: {order.price} DKK
                                                </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </OrderCard>
                            </Grid>
                        ))}
                    </Grid>
                    <div>Hvis der er problemer med din ordre, kontakt os venligst på info@miami.dk husk at oplyse dit ID, så står vi altid klar til at hjælpe</div>
                </>
            ) : (
                    <div>Loading</div>
                )}
        </Wrapper>
    )
}
export default CheckoutFinished;
