import React from 'react';
import styled from 'styled-components';
import {Typography} from '@material-ui/core';

interface IHeadline {
    title: string;
}

const StyledHeadline = styled(Typography)`
    border: 3px solid ${props => props.theme.palette.primary.main};
    padding: 4px 10px;
    margin-bottom: 30px;
    display: inline-flex;
    border-radius: 10px;
    color: ${props => props.theme.palette.primary.contrastText};
    background-color: ${props => props.theme.palette.primary.main};
`;

const Headline = ({title}: IHeadline) => {
    return (
        <StyledHeadline variant="h3" gutterBottom>
            {title}
        </StyledHeadline>
    )
}

export default Headline;
