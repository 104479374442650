import store from 'store';
import {IUser} from '../../../Interfaces/IUser';

const userString = 'user';

export const setUser = (user:IUser) => {
    store.set(userString, user);
}

export const getUser = ():IUser => {
    return store.get(userString);
}

export const logoutAction = () => {
    store.remove(userString);
}

export const checkLoggedIn = ():boolean => {
    const user = getUser();
    return !(!user)
}
