import React, { useEffect, useState } from 'react';
import {IImageList, ITextImagesArray} from '../../Interfaces/IImages';
import PhotoSection from './PhotoSection/PhotoSection';
import axios from 'axios';
import RenderPhotos from './PhotoSection/PhotoRender';
import {ImagesArray} from '../../static/api_uris';

const PhotoSectionFetcher = () => {
    const [images, setImages] = useState<IImageList>({
        image_list: [] as Array<ITextImagesArray>,
        loaded: false
    });

    useEffect(() => {
        const imageId = process.env.REACT_APP_UNIQUE_ID;
        const imgId = imageId? imageId : "";
        const uri = ImagesArray+imgId;
        axios
            .get(uri)
            .then(data => {
                const returnData = data["data"] as Array<ITextImagesArray>;
                var images:IImageList = {
                    image_list: returnData,
                    loaded: true
                };
                setImages(images as IImageList);
            })
            .catch(err => {
                setImages({
                    id: "",
                    image_list: [] as Array<ITextImagesArray>,
                    loaded: true
                } as IImageList)
                console.log("Error");
                console.log(err);
            })
    }, []);

    return(
        <RenderPhotos
            image_list={images.image_list}
            loaded={images.loaded}>
            <PhotoSection
                images={images.image_list} />
        </RenderPhotos>
    )
}

export default PhotoSectionFetcher;
