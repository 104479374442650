import React from 'react';
import styled from 'styled-components';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ContactForm from '../components/ContactFormCard';
import Headline from './Headline';
import {
    Grid,
} from '@material-ui/core';


interface ContactCardProps {
    spacing?: any; //TODO Get back to using a number. Compiler won't work atm
    success?: boolean;
    sent: boolean;
    endpoint: string;
}


const Header = () => {
    return (
        <Headline title="Kontakt"/>
    )
}

const StyledText = styled.div`
    text-align: center;
`;

const MarginGrid = styled(Grid)`
    margin: 10px;
`;

const StyledBackground = styled.div`
    // border-radius: 0 0 200px 0;
    // border-bottom: 2px solid ${props => props.theme.palette.primary.dark};
`;

const SuccessMessage = () => {
    return (
            <Grid container
                  direction="column"
                  justify="center"
                  alignItems="center"
            >
                <Grid item>
                    <Header />
                </Grid>
                <Grid item>
                    <Grid container
                          direction="column"
                          alignItems="center"
                          justify="center"
                    >
                        <StyledText>
                            <Grid item xs={12}>
                                <Grid container
                                      direction="column"
                                >
                                    <Grid item>
                                        <CheckCircleIcon color="primary" style={{ fontSize: 35}}/>
                                    </Grid>
                                    <Grid item>
                                        Tak for det.
                                    </Grid>
                                </Grid>
                            </Grid>
                            <MarginGrid item xs={12}>
                                Vi har nu modtaget din besked og vender hurtigt muligt tilbage til dig.
                            </MarginGrid>
                        </StyledText>
                    </Grid>
                </Grid>
            </Grid>
    )
}

const ContactCard = ({spacing=3, sent=false, success, endpoint}: ContactCardProps) => {
    const [submitted, setSubmitted] = React.useState(sent);

    function tmpFunc (isSent: boolean) {
        console.log("TMPFUNCTION WUHU")
        setSubmitted(isSent);
        success=true
    }
    if(submitted) {
        return (
            <StyledBackground>
                <SuccessMessage />
            </StyledBackground>
        )
    }
    if (!submitted) {
        return (
            <StyledBackground id="contact">
                <ContactForm
                    header={<Header />}
                    spacing={spacing}
                    endpoint={endpoint}
                    updateSent={tmpFunc}/>
            </StyledBackground>
        )
    }
    else {
        return (
            <StyledBackground>
                <div>
                    Noget gik galt
                </div>
            </StyledBackground>
        )
    }
}


export default ContactCard;
