import store from 'store'
import { IOrder } from '../../Interfaces/IProduct';

const orders = 'orders'

const orderValid = (order:IOrder):string[]=> {
    var errors:string[] = [];
    Object.entries(order).forEach(([key, value]) => {
        if(!value){
            errors.push(key);
        }
    });
    return errors;
}

export const addOrder = (order:IOrder) => {
    var updatedOrderList:IOrder[] = getOrderList();
    console.log("orderValid");
    const orderErrors:string[] = orderValid(order);
    if(orderErrors.length === 0){
        updatedOrderList.push(order);
        store.set(orders, updatedOrderList);
    }
    else {
        throw "Invalid order";
    }
    return getOrderList();
}

export const getOrderList = ():IOrder[] => {
    const orderList:IOrder[] = store.get(orders);

    return typeof orderList === "undefined"? [] : orderList;
}

export const removeOrder = (order:IOrder) => {
    var updatedOrderList:IOrder[] = getOrderList();
    store.set(orders, updatedOrderList.filter(o => o.order_number !== order.order_number));
    return getOrderList();
}

export const emptyOrderList = () => {
    store.remove(orders);
}
