import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import {
    Button,
    Grid,
    TextField
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckoutDetails from './CheckoutDetails';
import { getOrderList, removeOrder, emptyOrderList} from '../../utils/OrderStore';
import OverlayLoading from '../../utils/OverlayLoading';
import { IOrder } from '../../../Interfaces/IProduct';
import { VariantType, useSnackbar } from 'notistack';
import axios from 'axios';
import { GetCompanyDetails, OrderDetails, PostOrderBundle } from '../../../static/api_uris';
import ConfirmationDialog from '../../Dashboard/Utils/ConfirmationDialog';
import { IB2BCompany } from '../../../Interfaces/IUser';
import PaymentDetailsCard from './components/PaymentDetailsCard';
import MP from '../../../assets/MP.png'


interface IParameters{
    id: string;
    productId: string;
}

interface IFormattedOrder extends Omit<IOrder, 'product_details'|'color_id'>{
    product: string;
    color: string;
}

export interface IOrderBundle {
    orders: IFormattedOrder[];
    message: string;
    email: string;
}

interface IForm {
    fullName: string;
    printingName: string;
    email: string;
    company: string;
    country: string;
    note: string;
}


interface ICheckoutView {

}

const Wrapper = styled.div`

`;

const GridWrapper = styled(Grid)`
    max-width: 500px;
    margin: 0 auto;
`;

const CheckoutView = ({}:ICheckoutView) => {
    const [company, setCompany] = useState<IB2BCompany>({} as IB2BCompany);
    const formatOrder = (orders:IOrder[]):IFormattedOrder[] => {
        return orders.map(order => {
            return {
                color: order.color_id,
                company_id: order.company_id,
                product: order.product_details.product.id,
                dessing_number: order.product_details.product.dessing_number,
                gender: order.gender,
                order_number: order.order_number,
                size: order.size
            } as IFormattedOrder;
        })
    }
    const [formInfo, setFormInfo] = useState<IForm>({fullName: '', printingName: '', email: '', company: '', country: '', note: ''});
    const parameters = useParams() as IParameters;
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory()
    const [orders, setOrders] = useState<IOrder[]>(getOrderList())
    const [finalPrice, setFinalPrice] = useState<number>(0)
    //const [message, setMessage] = useState("")
    const [overlayOpen, setOverlayOpen] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);

    const postUpdate = (message: string,variant: VariantType) => {
        enqueueSnackbar(message, { variant });
    }

    const localRemoveOrder = (order:IOrder) => {
        const newOrderList = removeOrder(order);
        setOrders(newOrderList);
    }

    useEffect(() => {
        const webshopId = parameters.id
        if(webshopId){
            console.log("webshopId")
            console.log(webshopId)
             axios.get(GetCompanyDetails(webshopId))
             .then(response => {
                 console.log("fetchStoreDetails")
                 console.log(response["data"])
                 const data = response["data"]
                 setCompany(data);
             })
             .catch(error => {
                 console.log("Error fetching company");
                 console.log(error);
             })

        }

    },[parameters])

    useEffect(() => {
        const price = orders.reduce((a, b) => a + (Number(b.product_details.price) || 0), 0)
        setFinalPrice(price)
    }, [orders])



    const finishOrder = () => {
        setConfirmationOpen(false);
        setOverlayOpen(true)
        const message = `
        Fulde navn: ${formInfo.fullName}
        ${formInfo.printingName && (`Navn på print: ${formInfo.printingName}`)}
        Email: ${formInfo.email}
        Firma / afdeling: ${formInfo.company}
        Land: ${formInfo.country}
        Total pris: ${finalPrice} DKK
        Besked:
        ${formInfo.note}
        `;
        const orderBundle = {
            message: message,
            orders: formatOrder(orders),
            email: formInfo.email
        } as IOrderBundle
        console.log("sending")
        console.log(orderBundle)
        axios.post(PostOrderBundle(parameters.id), orderBundle)
            .then(response => {
                emptyOrderList();
                setOrders([] as IOrder[])
                //setMessage("")
                history.push(OrderDetails(company.id, response["data"]["public_id"]))
                setFormInfo({ company: "", country: "", email: "", fullName: "", printingName: "", note: "" });
                postUpdate("Bestilling er sendt til Fashion For Business. Tak for det!", "success");
            })
            .catch(err => {
                console.log("error sending order bundle")
                console.log(err)
                postUpdate("Bestilling blev ikke sendt til Fashion For Business. Prøv venligst igen.", "error");
            })
            .finally(() => setOverlayOpen(false))

        console.log("Finished order to send:")
        console.log(orderBundle);
    }

    const cancelFinish = () => {
        setConfirmationOpen(false);
    }

    return (
        <>
            <Wrapper>
                <Button
                    startIcon={<ArrowBackIcon />}
                    size="large"
                    variant="outlined"
                    style={{ margin: '15px' }}
                    onClick={() => history.goBack()}
                >
                    Tilbage
                </Button>
                <GridWrapper
                    container
                    direction="column"
                    justify="center"
                    alignItems="stretch"
                    spacing={4}
                >
                    <CheckoutDetails orders={orders} removeOrder={localRemoveOrder} />

                    {orders.length > 0 ? (
                        <>
                            {company.mobile_pay && (
                                <div>
                                    <PaymentDetailsCard
                                        headline="Husk MobilePay + navn"
                                        content={
                                            <div>
                                                <p>For at kunne gennemføre ordren skal du betale total pris <u>{finalPrice} DKK</u> via MobilePay.</p>
                                                <p>Når du har valgt 'BESTIL VARER', vil du få instruktioner på, hvordan du laver overførslen</p>
                                            </div>
                                        }
                                        image={MP}
                                    />
                                </div>
                            )}

                            {/**
                              * NOTE: DETTE ER KUN NOGET VI HAR SÅ VI KAN FÅ FREDERICIA SVØMME KLUB OP OG KØRE. VI ER NØDT TIL AT KIGGE PÅ
                              */}
                            {company.mobile_pay && company.company_name == 'Fredericia Svømme Klub' ? (
                                <>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            id="outlined-basic"
                                            label="Betaler Fulde navn"
                                            value={formInfo.fullName}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFormInfo({ ...formInfo, fullName: event.target.value })}
                                            variant="outlined" />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            id="outlined-basic"
                                            label="Navn der skal trykkes på tøjet"
                                            value={formInfo.printingName}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFormInfo({ ...formInfo, printingName: event.target.value })}
                                            variant="outlined" />
                                    </Grid>

                                </>
                            ) : (
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            id="outlined-basic"
                                            label="Fulde Navn"
                                            value={formInfo.fullName}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFormInfo({ ...formInfo, fullName: event.target.value })}
                                            variant="outlined" />
                                    </Grid>

                                )}
                            <Grid item>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Email"
                                    value={formInfo.email}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFormInfo({ ...formInfo, email: event.target.value })}
                                    variant="outlined" />
                            </Grid>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Firma / Afdeling"
                                    value={formInfo.company}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFormInfo({ ...formInfo, company: event.target.value })}
                                    variant="outlined" />
                            </Grid>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Land"
                                    value={formInfo.country}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFormInfo({ ...formInfo, country: event.target.value })}
                                    variant="outlined" />
                            </Grid>

                            <Grid item>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Note"
                                    multiline
                                    fullWidth
                                    rows={3}
                                    value={formInfo.note}
                                    variant="outlined"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFormInfo({ ...formInfo, note: event.target.value })}
                                />
                            </Grid>
                            <Grid item>
                                <Button onClick={() => setConfirmationOpen(true)} variant="outlined">
                                    Bestil varer
                                </Button>
                            </Grid>
                        </>
                    ) : (
                            <></>
                        )

                    }

                </GridWrapper>

            </Wrapper>
            <OverlayLoading open={overlayOpen} text="Sender din ordre til Fashion For Business" />
            <ConfirmationDialog
                open={confirmationOpen}
                headline="Bestil ordre"
                body="Er du sikker på at du vil bestille denne ordre?"
                okAction={finishOrder}
                cancelAction={cancelFinish}
            />
        </>
    )
}

export default CheckoutView;
