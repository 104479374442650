import React from 'react';
import styled from 'styled-components';
import {
    GridList,
    GridListTile,
} from '@material-ui/core';
import {ITextImagesArray} from '../../../Interfaces/IImages';
import PhotoGrid from './PhotoGrid';


interface IImageArray {
    images: Array<ITextImagesArray>;
}
const Wrapper = styled(GridList)`
    width: 100%;
    height: 450px;
    flex-wrap: nowrap;
    justify-content: space-between;
`;

const StyledGridListTile = styled(GridListTile)`
    height: 100% !important;
    width: auto !important;
`;

const PhotoSection = ({images}:IImageArray) => {

    return(
        <Wrapper>

            {images.map((imageList, index) => (
                <StyledGridListTile>
                    <PhotoGrid key={index} images={imageList}  />
                </StyledGridListTile>
            )
            )}
        </Wrapper>
    )
}

export default PhotoSection;
