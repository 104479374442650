import React, { FC } from 'react';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import styled from 'styled-components';
import axios from 'axios';
import {
    Paper,
    Card,
    Grid,
    Button
} from '@material-ui/core';
import TestimonialCard from './Testimonials/TestimonialCard';
import Headline from './Headline';
import { ITestimonial } from '../Interfaces/ITestimonial';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const development = true;

const PaperWidth = 400;


interface TestimonialProps {
    company_name: string;
    company_image: string;
    reviewer_name: string;
    reviewer_image: string;
    text: string;
}

interface TestimonialsCardProps {
    endpoint: string;
}

const Text = styled.p`
    text-align: center;
    margin-top: 40px
`;

const NavigationButton = styled(Button)`
    border-radius: 50%;
    width: 50px !important;
    height: 50px !important;
    min-width: 50px !important;
    color: ${props => props.theme.palette.primary.contrastText};
    background-color: ${props => props.theme.palette.primary.dark};
`

const NavigationContainer = styled.div`
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    justify-content: space-evenly;
`;


const TestimonialGrid = styled(Grid)`
    padding: 5px;
`

const Wrapper = styled.div`
    margin-top: 35px;
    margin-bottom: 35px;
`;

const HeadlineWrapper = styled.div`
    text-align: center;
`;

const TestimonialsCard = ({endpoint}: TestimonialsCardProps ) => {
    const [data, setData] = React.useState<Array<Array<ITestimonial>>>([]);
    const [drawData, setDrawData] = React.useState<Array<any>>([]);
    //const [data, setData] = React.useState<Array<ITestimonial>>([]);
    const [activeStep, setActiveStep] = React.useState(0);

    const screenWidth = window.innerWidth;
    const space = 20;
    const availableSlots = Math.floor(screenWidth/((space*2)+PaperWidth));
    const useSlots = availableSlots < 1 ? 1 : availableSlots;

    function matchMediaQuery (bp:string) {
        return
    }


    React.useEffect(() => {
        var uri = endpoint;
        axios
            .get<Array<ITestimonial>>(uri, {
                headers: {
                    "Content-Type": "application/json"
                },
            })
                .then(response => {
                    const responseData = response["data"];
                    const totalDataCounter = 0;

                    const finalArray: Array<Array<ITestimonial>> = [];
                    for (var j = 0; j< responseData.length; j += useSlots){
                        const currentArray:Array<ITestimonial> = [];
                        for (var i = 0; i < useSlots; i++){
                            const currentValue = i+j;
                            if (currentValue >= responseData.length)
                                break;
                            const currentData = responseData[currentValue];
                            currentArray.push(currentData);
                        }
                        finalArray.push(currentArray);
                    }
                    const tmp = finalArray.map(item => retrieveTestimonials(item));
                    setDrawData(tmp);
                    setData(finalArray);
                    //setData(response["data"]);
                })
                .catch(err => {
                    console.log("Error fetching "+endpoint);
                    console.log(err)
                });
    }, [])

    const maxSteps = data.length;

    const handleNext = () => {
        if(data){
            if (activeStep + 1 === maxSteps) {
                setActiveStep(0);
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
    }

    const handleBack = () => {
        if (data){
            if (activeStep <= 0){
                setActiveStep(maxSteps-1);
            }
            else{
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
            }
        }
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);

    };

    const retrieveTestimonials = (testimonials: Array<ITestimonial>) => {

        if (testimonials == null){
            return(
                <div>

                </div>
            )
        }
        return (
            <TestimonialGrid container
                direction="row"
                justify="space-evenly"
                alignItems="center"
                >
                {testimonials.map((item, index) => (
                    <TestimonialCard
                        key={index}
                        data={item} />
                )
                )}
            </TestimonialGrid>
        )
    }

    const displayTestimonials = (step: any) => {
        return (
                <AutoPlaySwipeableViews
                    axis={'x'}
                    index={step}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                    interval={development ? 10000000 : 10000}
                >
                    {drawData.map(item => item)}
                </AutoPlaySwipeableViews>
        )
    }


    return (
        <Wrapper id="udtalelser">
            <HeadlineWrapper>
                <Headline
                    title="Vores kunder fortæller" />
            </HeadlineWrapper>
            {displayTestimonials(activeStep)}
            <NavigationContainer>
                <NavigationButton variant="contained" onClick={handleBack}>
                    <KeyboardArrowLeft />
                </NavigationButton>
                <NavigationButton variant="contained" onClick={handleNext}>
                    <KeyboardArrowRight />
                </NavigationButton>
            </NavigationContainer>
            <Text>Hos Fashion For Business sætter vi en ære i, at I fortæller om os.</Text>
        </Wrapper>
    )
}

export default TestimonialsCard;
