import React from 'react';
import styled from 'styled-components';
import { IProductDetails } from '../../../Interfaces/IProduct';
import {
    Card,
    CardActionArea,
    CardContent,
    Divider,
    Grid,
    Typography
} from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import ColorDisplay from './ColorDisplay';
import ImageDisplay from './ImageDisplay';
import parse from 'html-react-parser';

interface IProductCard{
    productDetails: IProductDetails
}

const Wrapper = styled(Card)`
    width: 320px;
    border-radius: 0px;
`;

const ColorWrapper = styled(Grid)`

`;

const ColorSquare = styled.div`
    border: 1px solid black;
    width: 20px;
    height: 20px;
`;

const BrandWrapper = styled.div`
    margin-top: 20px;
`;

const PriceWrapper = styled.div`
    margin-top: 12px;
`;

const DescriptionWrapper = styled.div`
    margin-top: 15px;
    margin-bottom: 15px;
`;

const ProductCard = ({ productDetails }:IProductCard) => {
    let location = useLocation();
    const genderString = ():string => {
        const p = productDetails.product;
        if (p.male) return "M"
        else return "K";
    }
    const description = productDetails.product.description;
    return(
        <Wrapper elevation={1}>
            <ImageDisplay images={productDetails.images} displayColor={false} showThumbnails={false} />
            <CardActionArea component={Link} to={location.pathname + "/order/"+productDetails.product.id}>
                <Divider />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {productDetails.product.name} ({genderString()})
                    </Typography>
                    <ColorWrapper
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={1}
                    >
                        {productDetails.images.map(image => (
                            <Grid item xs={6}>
                                <ColorDisplay color={image.color} />
                            </Grid>
                        ))}
                    </ColorWrapper>
                    <BrandWrapper>
                        <Typography gutterBottom variant="subtitle1" component="h3">
                            Brand: {productDetails.product.brand.name}
                        </Typography>
                    </BrandWrapper>
                    {description? (
                        <>
                            <Divider />
                            <DescriptionWrapper>
                                {parse(description.replace(/(?:\r\n|\r|\n)/g, '<br />'))}
                            </DescriptionWrapper>
                        </>
                    ):(<></>)}
                    {productDetails.price? (
                        <>
                            <Divider />
                            <PriceWrapper>
                                Pris: {productDetails.price} DKK
                            </PriceWrapper>
                        </>
                    ):( <></> )}
                </CardContent>
            </CardActionArea>
        </Wrapper>
    )
}

export default ProductCard;
