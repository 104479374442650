import styled from 'styled-components';
import {
    Card,
    Grid,
    Typography
} from '@material-ui/core';
import { IOrder } from '../../../Interfaces/IProduct';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import RoundButton from '../../RoundButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ClearIcon from '@material-ui/icons/Clear';

interface ICheckoutDetails {
    orders: IOrder[];
    removeOrder?: (order:IOrder) => void;
}

const OrderGrid = styled(Grid)`
    padding: 15px;
    margin-top: 0px;
`;

const RemoveOrderButton = styled(RoundButton)`
    min-width: 32px;
    height: 30px;
    border-radius: 5px !important;
    border: 0px !important;
`;

const OrderCard = styled(Card)`
    border: 1px solid black;
    position: relative
`;

const ImageWrapper = styled.div`
    width: 70px;
    height: 70px;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
`;

const PriceDiv = styled.div`

`;


const CheckoutDetails = ({ orders, removeOrder }: ICheckoutDetails) => {
    var showPrice: boolean = false;
    if (orders.length > 0) {
        showPrice = orders[0].product_details.price !== null;
    }
    return (

        <OrderGrid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            spacing={2}
        >
            {orders.length > 0 ? (
                <>
                    {orders.map(order => (
                        <Grid item>
                            <OrderCard elevation={0}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <ImageWrapper>
                                            <Image src={order.product_details.images.filter(img => img.color.id === order.color_id)[0].image} />
                                        </ImageWrapper>
                                    </Grid>
                                    <Grid item>
                                        <Grid
                                            container
                                            direction="column"
                                            justify="center"
                                            alignItems="stretch"
                                        >
                                            <Grid item>
                                                <Typography variant="h6" gutterBottom>
                                                    {order.product_details.product.name} | {order.product_details.product.brand.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body1" gutterBottom>
                                                    {order.gender} | {order.product_details.product.sizes.filter(a => a.id === order.size)[0].name} {showPrice ? (<>| Pris: {order.product_details.price} DKK</>) : (<></>)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {removeOrder && (
                                    <RemoveOrderButton action={() => removeOrder(order)}>
                                        <ClearIcon />
                                    </RemoveOrderButton>
                                )}
                            </OrderCard>
                        </Grid>
                    ))}
                    {showPrice ? (
                        <PriceDiv>
                            <hr />
                            Total Pris: {orders.reduce((a, b) => a + (Number(b.product_details.price) || 0), 0)} DKK
                        </PriceDiv>
                    ) : (<></>)}
                </>
            ) : (
                    <Grid item>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center">
                            <Grid item>
                                <ShoppingBasketIcon />
                            </Grid>
                            <Grid item>
                                Ingen bestillinger fundet
                        </Grid>
                        </Grid>
                    </Grid>
                )}

        </OrderGrid>
    )
}

export default CheckoutDetails;
