import React, { ReactNode } from 'react';
import {
    Dialog,
    useTheme,
    useMediaQuery,
} from '@material-ui/core';

interface IStandardDialog{
    children: ReactNode;
    open: boolean;
    handleClose: () => void;
}

const StandardDialog = ({children, open, handleClose}:IStandardDialog) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))


    return(
        <Dialog fullScreen={fullScreen} fullWidth={true}  open={open} onClose={handleClose}>
            {children}
        </Dialog>
    )
}

export default StandardDialog;
