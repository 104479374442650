import React from 'react';
import styled from 'styled-components';
import {
    Grid,
    Typography,
    Divider
} from '@material-ui/core';

interface ITestimonialHeader {
    name: string;
    image: string;
}

const HeaderBG = styled.div`
    height: 120px;
    overflow: hidden;
    background: linear-gradient(40deg, ${props => props.theme.palette.secondary.dark},${props => props.theme.palette.primary.dark});
    width: 100%;
    border-radius: 5px 5px 0px 0px;
`;

const AvatarDiv = styled.div`
    margin-top: -70px;
    width: 130px;
    height: 130px;
    border: 5px solid white;
    border-radius: 50%;
`;

const ImgDiv = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 50%;
`;

const HeaderDivider = styled(Divider)`
    width: 95%;
`;

const TestimonialHeader = ({name, image}: ITestimonialHeader) => {
    return (
        <Grid container
              direction="column"
              justify="center"
              alignItems="center"
        >
            <HeaderBG></HeaderBG>
            <AvatarDiv>
                <ImgDiv
                    src={image}
                />
            </AvatarDiv>
            <Typography
                variant="h4">
                {name}
            </Typography>
            <HeaderDivider />
        </Grid>
    );
}


export default TestimonialHeader;
