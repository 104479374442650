import React, { useState, useRef, useEffect } from "react";
import styled from 'styled-components';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Grid,
    Typography,
    useTheme,
    useMediaQuery,
    TextField
} from '@material-ui/core';
import { IBrand, ICategory, IUpdateBrand, IUpdatedBrand} from '../../../Interfaces/IBrand';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Autocomplete, { AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete";


import ImageCrop from '../Utils/ImageCrop';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

interface IBrandEditor {
    brand: IUpdateBrand,
    open: boolean;
    handleBrandAddOrUpdate: (brand: IUpdatedBrand) => void;
    handleCancel: () => void;
}
type TonSelectFile = (evt: React.ChangeEvent<HTMLInputElement>) => void;
const BrandTitle = styled.div`

`;

const ImagePreview = styled.div`
    border: 1px solid;
    width: 372px;
    height: 230px;
    margin: 0 auto;
    margin-bottom: 20px;
`;

const ManageCategoriesWrapper = styled.div`

`;

const ExistingCategories = styled.div`
`;
const ImageGridItemContainer = styled(Grid)`
    height: 100%;
`;

const ImageWrapper = styled.div`
    display: flex;
    position: relative;
    height: 100%;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const BrandNameTextField = styled(TextField)`
    font-size: 25px;
    text-align: center;
    &input {
        font-size: 25px;
}
`;

const InputWrapper = styled(Grid)`
`;
const InputButton = styled(Button)`

`;

interface IImageSelector {
    image: string;
    onImageClick: TonSelectFile;
}
const ImageSelector = ({image, onImageClick}:IImageSelector) => {
    const hiddenFileInput = useRef<HTMLInputElement>(document.createElement("input"));

    const handleClick = () =>{
        if(hiddenFileInput !== null){
            hiddenFileInput.current.click()!;
        }
    };  

    return(
        <>
            <ImagePreview>
                { image === ""? (
                      <ImageGridItemContainer
                          container
                          direction="column"
                          justify="center"
                          alignItems="center"
                      >
                          <PhotoCamera />
                          <b>KLIK KNAP FOR AT TILFØJE BILLEDE</b>
                          <ArrowDownwardIcon />

                      </ImageGridItemContainer>
                ): (
                      <ImageWrapper>
                          <Image src={image} />
                      </ImageWrapper>
                )

                }
            </ImagePreview>
            <InputWrapper
                container
                direction="column"
                justify="center"
                alignItems="center"
            >
                <label htmlFor="filePicker">
                    <InputButton
                        variant="contained"
                        onClick={handleClick}
                        startIcon={<PhotoCamera/>}
                    >
                        Upload nyt billede
                    </InputButton>
                </label>

                <input type="file" id="filePicker" accept="image/*" style={{visibility: "hidden"}} ref={hiddenFileInput} onChange={onImageClick}/>
            </InputWrapper>
        </>
    )
}

interface ICropImageState {
    open: boolean;
    image: string;
    dimensions: [number, number]
}

interface MyInputProps {
  onKeyDown?: (event: object) => void;
}
interface MyParams extends AutocompleteRenderInputParams {
  inputProps: MyInputProps;
}

interface ITmp{
    updateBrand: IUpdateBrand;
    inCategories: ICategory[];
}
const UpdateBrandToUpdatedBrand = (updateBrand:IUpdateBrand, inCategories:string[]) => {
    // const prevCategories:ICategory[] = []
    // // Check if it is a existing brand (meaning we already have categories)
    // if(updateBrand.brand.id !== ""){
    //     updateBrand.allCategories.forEach(cat => {
    //         cat.brands.forEach(brand => {
    //             if(brand.id === updateBrand.brand.id){
    //                 prevCategories.push(cat);
    //             }
    //         })
    //     });
    // }

    const inCat:ICategory[] = [];
    for (var catName of inCategories){
        var existingCategory = updateBrand.allCategories.filter(cat => cat.name === catName);
        if(existingCategory.length > 0){
            inCat.push(existingCategory[0]);
        }
        else{
            inCat.push({
                brands: [] as IBrand[],
                id: "",
                name: catName
            } as ICategory)
        }
    }

    return {
        brand: updateBrand.brand,
        inCategories: inCat,
//        prevInCategories: prevCategories
    } as IUpdatedBrand;
}

const BrandEditor = ({open, brand, handleBrandAddOrUpdate, handleCancel}: IBrandEditor) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
    const emptyUpdateBrand = { allCategories: [] as ICategory[], brand: {} as IBrand, inCategories: [] as ICategory[] } as IUpdateBrand

    const [brandState, setBrandState] = useState<IUpdateBrand>(brand);
    const [selectedCategories, setSelectedCategories] = useState<string[]>(brand.inCategories.map(cat => cat.name));
    const [updatedBrandState, setUpdatedBrandState] = useState<IUpdatedBrand>(UpdateBrandToUpdatedBrand(brand, brand.inCategories.map(c => c.name)));
    const [cropImageState, setCropImageState] = useState<ICropImageState>({
        dimensions: [81,50],
        image: "",
        open: false
    });

    useEffect(() => {
        setBrandState(brand)
        setSelectedCategories(brand.inCategories.map(cat => cat.name));
    }, [brand])

    useEffect(() => {
        var selCategories = setUpdatedBrandState(UpdateBrandToUpdatedBrand(brand, selectedCategories));
    }, [selectedCategories])
    const handleClose = () => {
        setBrandState(emptyUpdateBrand);
        handleCancel()
    }

    const handleCloseOnOk = () => {
        handleBrandAddOrUpdate(UpdateBrandToUpdatedBrand(brandState, selectedCategories))
        handleCancel()
    }

    const handleCroppedImage = (image: string) => {
        var updatedBrand:IBrand = brandState.brand;
        updatedBrand.image = image;
        setBrandState({
            ...brandState,
            brand: updatedBrand
        });
        setCropImageState({
            ...cropImageState,
            image: "",
            open: false
        });
    }

    const handleSelectImage: TonSelectFile = (evt) => {
        if (evt.target.files && evt.target.files.length > 0) {
            const reader = new FileReader();
            reader.readAsDataURL(evt.target.files[0]);
            reader.onload = function(evt) {
                setCropImageState({
                    ...cropImageState,
                    image: reader.result? reader.result as string : "",
                    open: true
                })
            }
        }
    };

    const handleKeyDown = (event:any) => {
        switch (event.key) {
            case ",":
            case "Enter": {
                event.preventDefault();
                event.stopPropagation();
                if (event.target.value.length > 0) {
                    setSelectedCategories([...selectedCategories, event.target.value]);
                }
                break;
            }
            default:
        }
    };

    const updateBrandName = (e:any) => {
        var brand = brandState.brand;
        brand.name = e.target.value;
        setBrandState({
            ...brandState,
            brand: brand
        });
    }

    return(
        <>
            <Dialog fullScreen={fullScreen} fullWidth={true}  open={open} onClose={handleClose}>
                <DialogTitle>
                    <BrandTitle>
                        <BrandNameTextField fullWidth label="BRAND NAVN" id="standard-size-normal" size="medium" defaultValue={brandState.brand? brandState.brand.name : ""} onChange={(e: any) => updateBrandName(e)}/>
                    </BrandTitle>
                </DialogTitle>
                <ImageSelector
                    image={brandState.brand? brandState.brand.image : ""}
                    onImageClick={handleSelectImage}
                />
                <DialogContent>
                    <ManageCategoriesWrapper>
                        <Typography variant="h4" gutterBottom>
                            Kategorier:
                        </Typography>
                        <ExistingCategories>
                            <Autocomplete
                                multiple
                                freeSolo
                                id="tags-outlined"
                                options={brandState.allCategories.map(cat => cat.name)}
                                getOptionLabel={option => {
                                    return option;
                                }}
                                value={selectedCategories}
                                onChange={(event, newValue) => setSelectedCategories(newValue)}
                                filterSelectedOptions
                                renderInput={(params: MyParams) => {
                                    params.inputProps.onKeyDown = handleKeyDown;
                                    return (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="filterSelectedOptions"
                                            placeholder="Favorites"
                                            margin="normal"
                                            fullWidth
                                        />
                                    );
                                }}
                            />
                        </ExistingCategories>
                    </ManageCategoriesWrapper>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    {
                        brandState.brand? (
                            <Button onClick={handleCloseOnOk} color="primary">
                                OK
                            </Button>
                        ) : (<></>)
                    }
                </DialogActions>
            </Dialog>
            <ImageCrop
                dimensions={cropImageState.dimensions}
                image={cropImageState.image}
                onCropped={handleCroppedImage}
                open={cropImageState.open} />
        </>
    )
}

export default BrandEditor;
// <Autocomplete
//                                 multiple
//                                 id="tags-standard"
//                                 options={allCategories}
//                                 getOptionLabel={(option) => option}
//                                 defaultValue={brandState.inCategories.map(cat => cat.name)}
//                                 freeSolo
//                                 renderTags={(value: string[], getTagProps) =>
//                                     value.map((option: string, index: number) => (
//                                         <Chip
//                                             variant="outlined"
//                                             label={option}
//                                             {...getTagProps({ index })} />
//                                     ))
//                                 }
//                                 onInputChange={}
//                                 renderInput={(params) => (
//                                     <TextField
//                                         {...params}
//                                         variant="standard"
//                                         label="Vælg Kategorier"
//                                         placeholder="Kategorier"
//                                     />
//                                 )}
//                             />
