import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import { ITestimonial, ITestId, ITestimonialIdentity } from '../../../Interfaces/ITestimonial';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Grid,
    Typography,
    useTheme,
    useMediaQuery,
    TextField,
} from '@material-ui/core';
import StandardDialog from '../StandardDialog'
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import ReviewIdentityEditor from './ReviewIdentityEditor';

interface IReviewEditor{
    testimonial: ITestimonial;
    open: boolean;
    onClose: () => void;
    onOkClose: (value:ITestimonial) => void;
};



const GridWrapper = styled(Grid)`

`;




const ReviewEditor  = ({testimonial, open, onClose, onOkClose}:IReviewEditor) => {
    const [updatedReview, setUpdatedReview] = useState<ITestimonial>(testimonial);

    useEffect(() => {
        setUpdatedReview(testimonial);
    },[testimonial])

    const handleClose = () => {
        onClose();
    }

    const handleCloseOnOk = () => {
        console.log("Review editor handleCloseOnOk")
        console.log(updatedReview);
        onOkClose(updatedReview);
    }

    const updateCompanyDetails = (testId: ITestId) => {
        setUpdatedReview({
            ...updatedReview,
            company_image: testId.image,
            company_name: testId.name
        });
    }
    const updateReviewerDetails = (testId: ITestId) => {
        setUpdatedReview({
            ...updatedReview,
            reviewer_image: testId.image,
            reviewer_name: testId.name
        });
    }

    const onMessageChanged = (e:any) => {
        setUpdatedReview({
            ...updatedReview,

        })
    }

    return(
        <StandardDialog
            open={open}
            handleClose={handleClose}
        >
            <DialogTitle>
                {testimonial.id === ""? (
                    <Typography variant="h4" gutterBottom>
                        Opret ny udtalelse
                    </Typography>
                ):(
                    <Typography variant="h4" gutterBottom>
                        Opdater udtalelse fra {testimonial.reviewer_name}
                    </Typography>
                )}
            </DialogTitle>
            <DialogContent>
                <GridWrapper
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item>
                            <ReviewIdentityEditor
                                dimensions={[2,1]}
                                testId={{image: testimonial.company_image, name: testimonial.company_name} as ITestId}
                                title="FIRMA NAVN"
                                onComponentUpdated={updateCompanyDetails}
                            />
                        </Grid>
                        <Grid item>
                            <ReviewIdentityEditor
                                dimensions={[1,1]}
                                testId={{image: testimonial.reviewer_image, name: testimonial.reviewer_name} as ITestId}
                                title="NAVN"
                                onComponentUpdated={updateReviewerDetails}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <TextField
                            id="message"
                            defaultValue={testimonial.text}
                            name="message"
                            label="Besked"
                            multiline
                            variant="outlined"
                            rows={4}
                            fullWidth
                            rowsMax={4}
                            color="secondary"
                            onChange={(e:any) => setUpdatedReview({...updatedReview, text: e.target.value})}
                        />
                    </Grid>


                </GridWrapper>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleCloseOnOk} color="primary">
                    OK
                </Button>
            </DialogActions>

        </StandardDialog>
    )
}

export default ReviewEditor;
