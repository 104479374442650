import React from 'react';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';

interface ICHashLink {
    href: string;
    title: string;
}

const StyledHashLink = styled(HashLink)`
    color: inherit;
    text-decoration: none;
`;

const CHashLink = ({href, title} : ICHashLink) =>{
    return (
        <StyledHashLink smooth
                  to={href}>
           {title}
        </StyledHashLink>
    );
}

export default CHashLink;
