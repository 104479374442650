import React from 'react';
import styled from 'styled-components';
import {
    Toolbar,
    Hidden,
    List,
    ListItem,
    Typography,
    IconButton
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { IPage } from '../components/navigation/pages';
import Image from '../components/Image';
import NavigationItem from './navigation/NavigationItem';

interface HeaderProps {
    LogoIcon: React.ReactNode;
    onSidebarOpen: () => void;
    pages: IPage;
}

const StyledToolbar = styled(Toolbar)`
    width: 100%;
    margin: 0 auto;
    max-width: ${props => props.theme.palette.layout.contentWidth};
    padding: ${props => props.theme.spacing(0, 2)};
    box-sizing: border-box;
`;

const StyledWrapper = styled.div`
    background-color: ${props => props.theme.palette.primary.dark};
`;


const FlexGrow = styled.div`
    flex-grow: 1;
`;

const StyledList = styled(List)`
    display: flex;
    justify-content: space-between;
    align items: center;
    color: white;
`;

const StyledIconButton = styled(IconButton)`
    color: ${props => props.theme.palette.primary.contrastText};
`;

const StyledImage = styled(Image)`
    width: 100%;
    height: 100%;
`;

const Header = ({LogoIcon, onSidebarOpen, pages}: HeaderProps) => {

    return (
        <StyledWrapper>
            <StyledToolbar disableGutters>
                {LogoIcon}
                <FlexGrow />
                <Hidden smDown>
                    <StyledList>
                        {pages["pages"].map(page => (
                            <NavigationItem page_content={page}/>
                        ))}
                    </StyledList>
                </Hidden>
                <Hidden mdUp>
                    <StyledIconButton
                        aria-label="Menu"
                        onClick={onSidebarOpen}
                    >
                        <MenuIcon />
                    </StyledIconButton>
                </Hidden>
            </StyledToolbar>
        </StyledWrapper>
    )
}

export default Header;
