import React from 'react';
import styled from 'styled-components';
import Typed from 'react-typed';
import {Typography} from '@material-ui/core';
import Button from './Button';
import { Grid } from '@material-ui/core';
import CHashLink from './CHashLink';

interface IIntroCard {
    image: string;

}

const DivWrapper = styled.div`
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    position: relative;
    max-width: 1236px;
`;

//Left side from here down.
const DivLeft = styled.div`
    width: 100%;
    margin: 0 auto;
    padding: 48px 16px;
    max-width: 1236px;
    @media (min-width: 960px) {
        padding: 96px 64px
    }
    @media (min-width: 600px) {
        padding: 64px 64px
    }

`;

const LeftGridItem = styled(Grid)`
    display: flex;
`;

const HeadlineWrapper = styled.div`
    height: 290px;
    @media(max-width: 1100px){
        height: 360px;
    }
    @media(max-width: 960px){
        height: 290px;
    }
    @media(max-width: 460px){
        height: 360px;
    }
`;

const StyledTyped = styled(Typed)`
    color: #EA9E00;
    font-size: ${props => props.theme.typography.h2.fontSize};
    font-weight: bold;
`;

const OutlinedStyledButton = styled(Button)`
    font-size: 1.2em;
    color: ${props => props.theme.palette.primary.dark};
    border-color: ${props => props.theme.palette.primary.dark};
    border-radius: 10px;
`;

const StyledButton = styled(Button)`
    font-size: 1.2em;
    color: ${props => props.theme.palette.primary.contrastText};
    background-color: ${props => props.theme.palette.primary.dark};
    border-radius: 10px;
`;

// Right side from h100%ere down.
const DivRight = styled.div`
    flex: 0 0 50%;
    position: relative;
    max-width: 50%;
    height: 100%;
    @media(max-width: 1100px){
        flex: 0 0 100%;
        max-width: 100%;
    }
    @media(max-width: 960px){
        height: 300px;
    }

`;

const TmpDiv = styled.div`
    width: 50vw;
    height: 100%;
    position: relative;
    @media(max-width: 960px){
        width: 100vW
    }
`;

const Tmp2Div = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const ClipPathDiv = styled.div`
    clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
    shape-outside: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);

    left: 0%;
    width: 100%;
    height: 100%;
    position: absolute;
    @media(max-width: 960px){
       clip-path: inherit;
    }
`;


//TODO: Set background correctly.
const CoverImageDiv = styled.div`
    width: 100%;
    height: 100%;
    background: url(${process.env.PUBLIC_URL + 'ole-miami.png'});
    background-size: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #d5d4cf;
    background-position: right;
`;

const Text = styled.p`
    line-height: 1.3;
`;


const IntroCard = ({image}: IIntroCard) => {
    return (
        <DivWrapper>
            <Grid container>
                <LeftGridItem item md={6} sm={12}>
                    <DivLeft>
                        <HeadlineWrapper>
                            <Typography
                                variant="h2"
                            >
                                Vi sørger for
                            </Typography>
                            <StyledTyped
                                strings={[
                                    '']}
                                typeSpeed={40}
                                backSpeed={50}
                                showCursor={false}
                                loop />
                            <StyledTyped
                                strings={['professionelt workwear', "slidstærkt arbejdstøj", "logo", "kendte brands", "personlig rådgivning"]}
                                typeSpeed={40}
                                backSpeed={50}
                                loop
                            />
                        </HeadlineWrapper>
                        <Text>Hos Fashion for Business er kunden i centrum. Hos os får I personlig rådgivning og inspiration til jeres professionelle udstråling. Leveret af <a href="https://www.facebook.com/MiamiFredericia">Miami/Tøjeksperten</a>.</Text>
                        <Grid container spacing={2}>
                            <Grid xs={12} sm={6} >
                                <OutlinedStyledButton type="submit" variant="outlined">
                                    <CHashLink href="#brands" title="Se Brands" />
                                </OutlinedStyledButton>

                            </Grid>
                            <Grid xs={12} sm={6} >
                                <StyledButton type="submit" variant="contained">
                                    <CHashLink href="#contact" title="Kontakt os" />
                                </StyledButton>
                            </Grid>
                        </Grid>
                    </DivLeft>
                </LeftGridItem>
                <Grid item md={6} sm={12}>
                    <DivRight>
                        <TmpDiv>
                            <Tmp2Div>
                                <ClipPathDiv>
                                    <CoverImageDiv></CoverImageDiv>
                                </ClipPathDiv>
                            </Tmp2Div>
                        </TmpDiv>
                    </DivRight>
                </Grid>
            </Grid>
        </DivWrapper>
    )
}

export default IntroCard;
