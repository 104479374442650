import React from 'react';
import styled from 'styled-components';
import {
    Tooltip,
    IconButton,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

interface IAddButton{
    openEditor: () => void;
    title: string;
}

const StyledIconButton = styled(IconButton)`
    width: 150px;
    height: 150px;
    background: white;
    border-radius: 10px;
    color: black;
    &:hover {
         background-color: rgb(232, 232, 232);
    }
    margin: 25px;
    border: 1px solid;
`;

const StyledAddIcon = styled(AddIcon)`
    font-size: 70px;
`;



const AddButton = ({openEditor, title}: IAddButton) => {
    const openDialog = () => {
        openEditor();
    }

    return(
            <Tooltip title={title} aria-label="add">
                <StyledIconButton
                    aria-label="upload picture"
                    onClick={openDialog}>
                    <StyledAddIcon />
                </StyledIconButton>
            </Tooltip>
    )
}

export default AddButton;
