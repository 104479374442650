import React from 'react';
import styled from 'styled-components';
import {
    Grid
} from '@material-ui/core';
import {
    Person,
    EmojiObjects,
    CheckCircle,
    Star,
    WatchLater
} from '@material-ui/icons/';

interface IIdeologyCard {
    icon: any;
    text: string;
}

const Wrapper = styled(Grid)`
    height: 100%;
`;

const IdeologyWrapper = styled(Grid)`
    font-size: 1.5rem;
    padding-top: 5px;
    padding-bottom: 10px;
    color: #BD7738;
`;

const IconWrapper = styled.svg`
    width: 80px;
    height: 80px;
`;
const TextWrapper = styled.div`
    text-align: center;
`;

const IdeologyCard = ({icon, text}: IIdeologyCard) => {
    return(
        <Grid item
              xs={6} md={3}>
            <IdeologyWrapper container
                             direction="column"
                             alignItems="center"
                            justify="center"
            >
                <IconWrapper>
                    {icon}
                </IconWrapper>
                <TextWrapper>
                    {text}
                </TextWrapper>
            </IdeologyWrapper>
        </Grid>
    )

}

const IdeologySection = () => {
    return(
        <Wrapper container
                 justify="center"
            alignItems="center">
            <IdeologyCard
                icon={<Star/>}
                text={"VIP oplevelse"} />
            <IdeologyCard
                icon={<CheckCircle />}
                text={"Kvalitet"} />
            <IdeologyCard
                icon={<EmojiObjects/>}
                text={"Inspiration"} />
            <IdeologyCard
                icon={<WatchLater/>}
                text={"35 års erfaring"} />
        </Wrapper>
    )
}

export default IdeologySection;
// <Wrapper>
//     <IdeologyCard
//         icon={Person}
//         text={"Kunden i centrum"} />
//     <IdeologyCard
//         icon={EmojiObjects}
//         text={"Inspiration"} />
// </Wrapper>
