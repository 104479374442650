import React from 'react';
import BrandCard from '../components/BrandCard';
import styled from 'styled-components';
import {IBrand, ICategory} from '../Interfaces/IBrand';
import Headline from './Headline';
import {
    Button,
    Grid,
    GridList,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

interface ILargeListPicker{
    categories: Array<ICategory>;
}

const Wrapper = styled.div`
    margin: 0 auto;
`;

const HeadSelector = styled.div`

`;
const BrandGrid = styled(GridList)`
    flex-wrap: nowrap;
    hejtranform translateZ(0);
    border: 1px solid;
    margin-top: -1px !important;
    max-width: 100%;
`;

const StyledToggleButton = styled(ToggleButton)`
    &.Mui-selected{
        border-top: 1px solid black;
        border-right: 1px solid;
        border-left: 1px solid !important;
        border-radius: 4px 4px 0px 0px !important;
        border-bottom: 1px solid white;
        background-color: #29395a;
        color: white;
        &:hover{
            background-color: #29395af2;
        }
    }
`

const LargeListPicker = ({categories}:ILargeListPicker) => {
    const [selectedCategory, setSelectedCategory] = React.useState<string | string>("Ingen kategorier")
    const [selectedBrands, setSelectedBrands] = React.useState<Array<IBrand>>([])
    const isInitialMount = React.useRef(true);



    React.useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            setSelectedCategory(categories[0]["name"])
            setSelectedBrands(categories[0]["brands"])
        }
    }, [categories]);
    const handleChange = (event: React.MouseEvent<HTMLElement>, category: string ) => {
        if(category !== selectedCategory && category){
            setSelectedCategory(category);
            const brandsList = categories.find(item => item["name"] === category);
            if (brandsList){
                setSelectedBrands(brandsList["brands"]!)
            }
            else{
                setSelectedBrands([])
            }
        }
    };

    return (
        <Grid container
                  direction="column"
                  alignItems="center"
            >

            <Headline title="Brands"/>
            <HeadSelector>
                <ToggleButtonGroup
                    value={selectedCategory}
                    exclusive
                    onChange={handleChange}
                    aria-label="text alignment"
                >
                    {categories.map(category => {
                        return (
                            <StyledToggleButton value={category.name}>
                                {category.name}
                            </StyledToggleButton>
                        )
                    })}
                </ToggleButtonGroup>
            </HeadSelector>
            <BrandGrid>
                {selectedBrands.map(b => {
                    return(
                        <BrandCard
                            image={b["image"]}
                            name={b["name"]} />
                    )
                })}
            </BrandGrid>

        </Grid>
    )
}

export default LargeListPicker;
