import React from 'react';
import styled from 'styled-components';
import {Grid} from '@material-ui/core';
import FormatQuoteRoundedIcon from '@material-ui/icons/FormatQuoteRounded';


interface ITestimonialBody {
    image: string;
    company_name: string;
    text: string;
}


const StyledText = styled.div`
    text-align: center;
`;

const CompanyImageDiv = styled.div`
    justify-content: center;
    display: flex;
`;

const CompanyImage = styled.img`
    width: 350px;
    height: 100px;
    object-fit: contain;
`;


const TestimonialBody = ({image, text, company_name}: ITestimonialBody) => {
    return (
        <Grid container
              direction="column"
              justify="center"
              alignItems="center"
        >
            <Grid item>
                <Grid container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      style={{paddingTop: "20px"}}
                >
                    <Grid item xs={1}
                          style={{paddingRight: "10px"}}>
                        <h1 style={{float: "right"}}><FormatQuoteRoundedIcon /></h1>
                    </Grid>
                    <Grid item xs={10}>
                        <StyledText>
                            <em>{text}</em>
                        </StyledText>
                    </Grid>
                    <Grid item xs={1}
                          style={{paddingLeft: "10px"}}>
                        <h1><FormatQuoteRoundedIcon /></h1>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <CompanyImageDiv>
                    <CompanyImage
                        src={image}
                        alt={company_name}
                    />
                </CompanyImageDiv>

            </Grid>
        </Grid>
    );
}


export default TestimonialBody;
