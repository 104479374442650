import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import {
    Card,
    CardContent,
    CardHeader
} from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error';
import Avatar from '@material-ui/core/Avatar';

interface IProps {
    headline: string;
    content: React.ReactNode;
    image: string;
}

const StyledCard = styled(Card)`
    width: 100%;
    height: 300px;
    border-color: #29395a;
    border: 1px solid #29395a;
`;

const StyledCardHeader = styled(CardHeader)`
`;

const ImageWrapper = styled.div`
    background-color: #29395a;
    height: 100px
`;

const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

const IconWrapper = styled.div`
    padding: 5px;
`
const Important = styled.p`
    margin-bottom: 0px;
    font-size: 25px;
    font-weight: bold;
    margin-top: 11px;
    margin-left: -22px;
`


const PaymentDetailsCard = ({ headline, content, image }: IProps) => {
    return (
        <StyledCard>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <IconWrapper>
                    <ErrorIcon style={{ fontSize: 40 }}/>
                    </IconWrapper>
                </Grid>
                <Grid item xs={10}>
                    <Important>Vigtig info - {headline}</Important>
                </Grid>
            </Grid>
            <ImageWrapper>
                <StyledImage
                    alt={headline}
                    src={image}
                    title={headline}
                />
            </ImageWrapper>
            <CardContent>
                {content}
            </CardContent>
        </StyledCard>

    )
}
export default PaymentDetailsCard;
