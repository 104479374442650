import React, {useState, useEffect} from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { GetAllCompanies } from '../../../static/api_uris';
import { IB2BCompany } from '../../../Interfaces/IUser';
import WebshopCard from "./WebshopCard"
import Grid from '@material-ui/core/Grid';

const HeadlineWrapper = styled.div`
    width: 100%;
    text-align: center;
`;

const Wrapper = styled.div`
padding: 12px;
`

const ListCompanies = () => {
    const [webshops, setWebshops] = useState<IB2BCompany[]>([])

    useEffect(() => {
        fetchWebshops()
    },[])

    const fetchWebshops = () => {
        axios.get(GetAllCompanies)
        .then(response => {
            setWebshops(response["data"]);
        })
        .catch(err => {
            console.log("error webshops")
            console.log(err);
        })
    }

    return(
        <>
            <HeadlineWrapper>
                <h1>Webshops</h1>
                <Wrapper>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="center"
                    >
                        {webshops.map(webshop => (
                            <Grid item>
                                <WebshopCard
                                    image={webshop.company_image}
                                    name={webshop.company_name}
                                    id={webshop.id}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Wrapper>
            </HeadlineWrapper>
        </>
    )
}

export default ListCompanies;
