import React, { useState, useRef, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@material-ui/core';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

interface IImageCrop{
    open: boolean;
    image: string;
    onCropped: (newImage: string) => void;
    dimensions: [number, number];
    circularCrop?: boolean;
}

interface CompletedCrop {
  x: number;
  y: number;
  width: number;
  height: number;
  unit: string;
  aspect: number;
}

const ImageCrop = ({open, image, onCropped,dimensions, circularCrop}: IImageCrop) => {
    const isCircular = typeof circularCrop === "undefined"? false : circularCrop;
    const imageRef = new Image();
    imageRef.src = image
    const imgRef = useRef<HTMLImageElement>();
    imgRef.current = imageRef;
    //Construct the canvas we download the image from later
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    // This is the image we return. It's the cropped image result
    const [croppedImage, setCroppedImage] = useState("");
    const [completedCrop, setCompletedCrop] = useState<CompletedCrop>();
    const [crop, setCrop] = useState<Crop>({ unit: '%', width: 100, aspect: dimensions[0] / dimensions[1] });


    const handleClose = () => {
        onCropped(croppedImage);
    };

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
        const pixelRatio = window.devicePixelRatio;

        const width = crop.width || 0;
        const height = crop.height || 0;
        canvas.width = width * pixelRatio;
        canvas.height = height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";

        const cropC = crop as CompletedCrop;

        ctx.drawImage(
            image,
            cropC.x * scaleX,
            cropC.y * scaleY,
            cropC.width * scaleX,
            cropC.height * scaleY,
            0,
            0,
            cropC.width,
            cropC.height
        );
        const cv = previewCanvasRef.current as HTMLCanvasElement;
        setCroppedImage(cv.toDataURL("image/png"));
    }, [crop, completedCrop]);


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            disableBackdropClick={true}
            disableEscapeKeyDown={true}>
            <DialogTitle id="form-dialog-title">Beskær billede</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <ReactCrop
                        circularCrop={isCircular}
                        src={image}
                        crop={crop}
                        onChange={(c) => setCrop(c)}
                        onComplete={(c) => setCompletedCrop(c as CompletedCrop)}/>
                </DialogContentText>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    OK
                </Button>
            </DialogActions>
            <canvas
                ref={previewCanvasRef}
                style={{
                    width: Math.round(completedCrop?.width ?? 0),
                    height: Math.round(completedCrop?.height ?? 0),
                    display: 'none'
                }}
            />
        </Dialog>
    )
}

export default ImageCrop;
