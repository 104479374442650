import React from 'react';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import {ITextImagesArray} from '../../../Interfaces/IImages';
import styled from 'styled-components';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import Loading from '../../utils/Loading';

interface IImageList {
    children: React.ReactNode;
    image_list: Array<ITextImagesArray>;
    loaded: boolean;
    renderEmpty?: boolean
}

const LoadingWrapper = styled.div`
    margin: 0 auto;
    text-align: center;
    padding: 30px;
    position: relative;
`;

const StyledImageSearchIcon = styled(ImageSearchIcon)`
    font-size: 4.5rem;
`;

const RenderPhotos = ({children, image_list, loaded, renderEmpty}: IImageList) => {
    if ((loaded && image_list.length > 0) || (loaded && renderEmpty)){
        return(
            <div>
                {children}
            </div>
        )
    }
    else if(loaded && image_list.length === 0){
        return(
            <LoadingWrapper>
                <StyledImageSearchIcon />
                <p>Der blev ikke fundet nogen billeder</p>
            </LoadingWrapper>
        )
    }
    else if(!loaded){
        return(
            <Loading message="Loader billeder..." />
        )
    }
    else {
        return(
            <LoadingWrapper>
                <p>Der skete en ukendt fejl</p>
            </LoadingWrapper>
        )
    }
}

export default RenderPhotos;
